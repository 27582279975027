import React, { Component, useState } from 'react';
import { Checkbox } from 'SharedFrontend/src/Components/Forms/Input';
import { scrollTo } from '../../../helpers/scrollHelpers';
import {
  reservationsLabels,
  sendAnalytics,
  signupDefaultStrings,
} from './registrationFormHelpers';
import SummaryItem from '../../UI/forms/SummaryItem';
import SummaryEditButton from './summaryEditButton';
import { postEnrollment } from '../../../api/enrollment';
import SubmitButton from '../../UI/buttons/SubmitButton';

function RegistrationSummary({
  values,
  editForm,
  recruitmentSection,
  setIsComplete,
  setWebId,
  antiForgeryToken,
}) {
  const [conditionsAccepted, setConditionsAccepted] = useState(false);
  const [showConditionsError, setShowConditionsError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [isSending, setIsSending] = useState(false);

  if (!values) {
    return null;
  }

  const sendForm = () => {
    if (conditionsAccepted) {
      setIsSending(true);
      sendAnalytics('Button click', 'Send innmelding');
      const applicationValues = { ...values, workplaces: [] };
      postEnrollment(applicationValues, antiForgeryToken)
        .then((response) => {
          setIsSending(false);
          setIsComplete(true, response.webID);
          if (typeof ga !== 'undefined') {
            // eslint-disable-line no-undef
            ga('send', 'pageview', '/innmelding/kvittering'); // eslint-disable-line no-undef
          }
          scrollTo(0, 0);
        })
        .catch((error) => {
          setIsSending(false);
          setServerError(true);
          sendAnalytics('Errors', 'Form sending');
        });
      setShowConditionsError(false);
    } else {
      setShowConditionsError(true);
      sendAnalytics('Errors', 'Conditions not accepted');
    }
  };

  const { membership, personalia, workplaces, reservations, recruiterPhone } =
    values;
  return (
    <div className="registration-summary">
      <div className="registration-summary__content">
        <div>
          <h2>Oppsummering og innsending</h2>
          {recruitmentSection && (
            <>
              <h3>
                {recruitmentSection.title || signupDefaultStrings.recruit.title}
              </h3>
              <SummaryItem
                label={signupDefaultStrings.recruit.inputLabel}
                wrapperElement="div"
                className="vspace--medium"
              >
                {recruiterPhone}
              </SummaryItem>
              <SummaryEditButton
                analyticsEvent="Endre verver"
                onClick={() => {
                  editForm('recruitment');
                }}
              />
            </>
          )}
          <h3 id="membership">Medlemskap og utdanning</h3>
          <ul>
            <SummaryItem label="Studiested">{membership.school}</SummaryItem>
            <SummaryItem label="Utdanning">
              {membership.studentEducation}
            </SummaryItem>
            <SummaryItem label="Planlagt fullført">
              {membership.graduationSemester === 'spring' ? 'Våren' : 'Høsten'}{' '}
              {membership.graduationYear}
            </SummaryItem>
          </ul>
          <SummaryEditButton
            analyticsEvent="Endre medlemskap og utdanning"
            onClick={() => {
              editForm('membership');
            }}
          />
          <h3 id="personalia">Personalia</h3>
          <ul>
            <SummaryItem label="Navn">{personalia.firstname}</SummaryItem>
            <SummaryItem label="Etternavn">{personalia.lastname}</SummaryItem>
            {!personalia.hasAddressAbroad && (
              <SummaryItem label="Adresse">
                {personalia.street} {personalia.streetNumber}{' '}
                {personalia.streetEntrance} {personalia.zipCode}{' '}
                {personalia.city}{' '}
              </SummaryItem>
            )}
            {personalia.coAddress && (
              <SummaryItem label="CO-adresse">
                {personalia.coAddress}
              </SummaryItem>
            )}
            {personalia.postbox && (
              <SummaryItem label="Postboks">{personalia.postbox}</SummaryItem>
            )}
            {personalia.addressAbroad && (
              <SummaryItem label="Adresse (utlandet)">
                <strong>Adresse (utlandet):</strong> {personalia.addressAbroad}
                {personalia.streetNumber}
              </SummaryItem>
            )}
            {personalia.country && (
              <SummaryItem label="Land">{personalia.country}</SummaryItem>
            )}
            <SummaryItem label="E-post">{personalia.email}</SummaryItem>
            <SummaryItem label="Telefonnummer">
              {personalia.mobile}
            </SummaryItem>
            <SummaryItem label="Personnummer">{personalia.ssn}</SummaryItem>
          </ul>
          <SummaryEditButton
            analyticsEvent="Endre personalia"
            onClick={() => {
              editForm('personalia');
            }}
          />
          <h3 id="reservations">Medlemsfordeler</h3>
          <p>Det ønskes reservasjon om å få tilsendt informasjon via:</p>
          <ul className="registration-summary__member-benefits">
            {reservations.email && <li>{reservationsLabels.email}</li>}
            {reservations.sms && <li>{reservationsLabels.sms}</li>}
            {reservations.adsSoMe && <li>{reservationsLabels.adsSoMe}</li>}
          </ul>
          <SummaryEditButton
            analyticsEvent="Endre reservasjoner"
            onClick={() => {
              editForm('reservations');
            }}
          />
          <p>
            Jeg aksepterer at Utdanningsforbundet registrerer meg som
            studentmedlem i forbundet, jf. personopplysningslovens §8. Som
            medlem i Utdanningsforbundet er jeg forpliktet til å melde fra til
            forbundet om endringer av betydning for medlemskapet. Videre er jeg
            forpliktet til å betale den til enhver tid gjeldende kontingent,
            normalt ved at Utdanningsforbundet gir melding til arbeidsgiver om
            trekk i lønn.
          </p>
          <div>
            <Checkbox
              id="acceptCheckbox"
              name="acceptCheckbox"
              className="hidden-checkbox"
              onChange={(e) => setConditionsAccepted(e.target.checked)}
              checked={conditionsAccepted}
              label={
                <>
                  Jeg bekrefter at opplysningene jeg har oppgitt er korrekte og
                  at jeg har forstått medlemskriteriene.{' '}
                  <span className="mandatory">*</span>
                </>
              }
            />
            {showConditionsError && !conditionsAccepted && (
              <div className="validation-msg">
                Vennligst bekreft at du har gitt riktige opplysninger og at du
                har forstått medlemskriteriene.
              </div>
            )}
          </div>
          <SubmitButton
            id="submit-button"
            onClick={sendForm}
            isSubmitting={isSending}
          >
            Send inn
          </SubmitButton>
        </div>
      </div>
    </div>
  );
}

export default RegistrationSummary;
