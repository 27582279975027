import React from 'react';
import PropTypes from 'prop-types';

function AdminDebugSelector({ adminDebug, setCurrentStep }) {
    if (!adminDebug) {
        return null;
    }
    return (
        <label className="admin-debug">
            Velg hvilket steg du vil se (kun for admin)
            <select onChange={(e) => setCurrentStep(parseInt(e.target.value))}>
                <option value="1">Hovedskjema</option>
                <option value="2">Oppsummering</option>
                <option value="3">Bekreftelse</option>
                <option value="4">Bekreftelse etter endringer</option>
            </select>
        </label>
    );
}

AdminDebugSelector.propTypes = {};

export default AdminDebugSelector;
