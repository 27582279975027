import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const ErrorMessage = ({ errorMessage, children, id, className }) => {
    const hasError = errorMessage || children;
    return (
        <strong
            id={id}
            className={classNames(
                'input--errormessage',
                {
                    'has-error': hasError,
                },
                className
            )}
        >
            {errorMessage}
            {children}
        </strong>
    );
};

ErrorMessage.propTypes = {
    errorMessage: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};

ErrorMessage.defaultProps = {
    errorMessage: null,
    id: null,
};

export default ErrorMessage;
