import { useState, useEffect } from 'react';

let idCounter = 0;

export default function useUniqueId(prefix) {
    const [id, setId] = useState('');

    useEffect(() => {
        setId(`${prefix}-${idCounter}`);
        idCounter += 1;
    }, []);

    return id;
}
