import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import ValidationError from '../ValidationError';

function ValidationErrorFormik({ fieldName }) {
  const [field, meta] = useField({ name: fieldName });

  const { touched, error } = meta;

  return <ValidationError touched={touched} error={error} />;
}

ValidationErrorFormik.propTypes = {};

export default ValidationErrorFormik;
