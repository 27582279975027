import { windowAvailable } from './renderUtils';

// Just a little helper function to make sure the object is in the right format
export const createQueryParam = (key, value) => ({
    key,
    value,
});

export const defaultQueryKeys = {
    page: 'pageNumber',
    searchTerm: 'sok',
    sort: 'sort',
};

export function getQueryParams() {
    if (windowAvailable) {
        return new URLSearchParams(window.location.search);
    }
    return null;
}
/**
 *
 * @param {array} queryParams Key value pairs of query parameters
 */
export function addQueryParams(queryParams) {
    // const urlParams = new URLSearchParams(window.location.search);
    // urlParams.set(key, value);
    // window.location.search = urlParams;

    if (
        windowAvailable &&
        'URLSearchParams' in window &&
        queryParams.length > 0
    ) {
        const searchParams = new URLSearchParams(window.location.search);
        queryParams.forEach(queryParam => {
            searchParams.set(queryParam.key, queryParam.value);
        });
        const newRelativePathQuery = `${
            window.location.pathname
        }?${searchParams.toString()}${window.location.hash}`;
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, '', newRelativePathQuery);
    }
}
export function addQueryParam(key, value) {
    addQueryParams([createQueryParam(key, value)]);
}

export function removeQueryKeys(keys) {
    if (windowAvailable && 'URLSearchParams' in window && keys.length > 0) {
        const searchParams = new URLSearchParams(window.location.search);
        keys.forEach(key => {
            searchParams.delete(key);
        });
        const newRelativePathQuery = `${
            window.location.pathname
        }?${searchParams.toString()}${window.location.hash}`;
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, '', newRelativePathQuery);
    }
}
export function addTrailingSlashIfNotPresent() {
    if (windowAvailable) {
        const path = window.location.pathname;
        if (path.charAt(path.length - 1) !== '/') {
            window.history.replaceState(
                null,
                '',
                `${window.location.pathname}/${window.location.search}`
            );
        }
    }
}

export default {
    addQueryParam,
    addQueryParams,
    getQueryParams,
    createQueryParam,
    removeQueryKeys,
    addTrailingSlashIfNotPresent,
    defaultQueryKeys,
};
