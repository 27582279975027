import React from 'react';
import PropTypes from 'prop-types';

function PhoneDisplay({ label, number, className }) {
    return (
        <span className={`tel ${className}`}>
            {label}
            <a href={`tel:${number}`}>{number}</a>
        </span>
    );
}

PhoneDisplay.propTypes = {
    label: PropTypes.string,
    number: PropTypes.string.isRequired,
    className: PropTypes.string,
};

PhoneDisplay.defaultProps = {
    label: '',
    className: '',
};

export default PhoneDisplay;
