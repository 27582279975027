import {
  CheckboxFormik,
  RadioFormik,
} from 'SharedFrontend/src/Components/Forms/Formik';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { postEnrollmentUpdate } from '../../../api/enrollment';
import SubmitButton from '../../UI/buttons/SubmitButton';
import {
  postSignupInitialValues,
  postSignupValidationSchema,
  subscriptionTypes,
} from './registrationFormHelpers';
import RegistrationSection from './registrationSection';
import WorkplaceSection from './workplaceSection';

function getAge(ssn) {
  const year = ssn.substring(4, 6);
  const month = ssn.substring(2, 4);
  const day = ssn.substring(0, 2);
  const birthDate = new moment(
    `${year < 50 ? '20' : '19'}${year}-${month}-${day}`
  );
  const today = moment();
  return today.diff(birthDate, 'years');
}

function RegistrationComplete({
  ssn,
  formSections,
  webId,
  onComplete,
  antiForgeryToken,
}) {
  const {
    postRegistrationIntroSection,
    subscriptionsIntroSection,
    subscriptionsMainSection,
    subscriptionsExtraSection,
    membershipExtraIntroSection,
    membershipExtraInsuranceSection,
    membershipExtraWorkplaceSection,
    membershipExtraMagazineSection,
    membershipActiveEngagementSection,
  } = formSections;

  const submitForm = (values, actions) => {
    actions.setSubmitting(true);
    postEnrollmentUpdate(webId, values, antiForgeryToken).then((response) => {
      actions.setSubmitting(false);
      onComplete(response);
    });
  };

  return (
    <div className="registration-receipt-view">
      <Formik
        onSubmit={submitForm}
        enableReinitialize
        initialValues={postSignupInitialValues}
        validationSchema={postSignupValidationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <RegistrationSection
              sectionData={postRegistrationIntroSection}
              // defaultData={signupDefaultStrings.recruit}
              id="postRegistrationIntro"
            />
            <RegistrationSection
              sectionData={subscriptionsIntroSection}
              // defaultData={signupDefaultStrings.recruit}
              id="subscriptionsIntro"
            />
            <RegistrationSection
              sectionData={subscriptionsMainSection}
              // defaultData={signupDefaultStrings.recruit}
              id="subscriptionsMain"
            >
              <div className="radio-group">
                <RadioFormik
                  name="subscriptionMain"
                  value={subscriptionTypes.digital.id}
                  label={subscriptionTypes.digital.label}
                />
                <RadioFormik
                  name="subscriptionMain"
                  value={subscriptionTypes.mail.id}
                  label={subscriptionTypes.mail.label}
                />
                <RadioFormik
                  name="subscriptionMain"
                  value={subscriptionTypes.both.id}
                  label={subscriptionTypes.both.label}
                />
                <RadioFormik
                  name="subscriptionMain"
                  value={subscriptionTypes.none.id}
                  label={subscriptionTypes.none.label}
                />
              </div>
            </RegistrationSection>
            <RegistrationSection
              sectionData={subscriptionsExtraSection}
              // defaultData={signupDefaultStrings.recruit}
              id="subscriptionsExtra"
            >
              <div className="radio-group">
                <RadioFormik
                  name="subscriptionExtra"
                  value={subscriptionTypes.digital.id}
                  label={subscriptionTypes.digital.label}
                />
                {/* <RadioFormik
                  name="subscriptionExtra"
                  value={subscriptionTypes.mail.id}
                  label={subscriptionTypes.mail.label}
                /> */}
                <RadioFormik
                  name="subscriptionExtra"
                  value={subscriptionTypes.both.id}
                  label={subscriptionTypes.both.label}
                />
                <RadioFormik
                  name="subscriptionExtra"
                  value={subscriptionTypes.none.id}
                  label={subscriptionTypes.none.label}
                />
              </div>
            </RegistrationSection>
            <RegistrationSection
              sectionData={membershipExtraIntroSection}
              // defaultData={signupDefaultStrings.recruit}
              id="membershipExtraIntro"
            />
            <RegistrationSection
              sectionData={
                getAge(ssn) < 30
                  ? membershipExtraInsuranceSection
                  : {
                      ...membershipExtraInsuranceSection,
                      text: '<p>Du er over 29 år og kan derfor dessverre ikke bestille studentforsikringen.</p>',
                    }
              }
              // defaultData={signupDefaultStrings.recruit}
              id="membershipExtraInsurance"
            >
              {getAge(ssn) < 30 && (
                <CheckboxFormik
                  name="studentInsurance"
                  label="Jeg ønsker å bestille studentforsikringen"
                />
              )}
            </RegistrationSection>
            <RegistrationSection
              sectionData={membershipExtraWorkplaceSection}
              // defaultData={signupDefaultStrings.recruit}
              id="membershipExtraWorkplace"
            >
              <WorkplaceSection />
            </RegistrationSection>
            <RegistrationSection
              sectionData={membershipExtraMagazineSection}
              // defaultData={signupDefaultStrings.recruit}
              id="membershipExtraMagazine"
            >
              <CheckboxFormik
                name="extraMagazine"
                label="Jeg ønsker å bestille Spesialpedagogikk for 150 kr/år"
              />
            </RegistrationSection>
            {/* <RegistrationSection
              sectionData={membershipActiveEngagementSection}
              // defaultData={signupDefaultStrings.recruit}
              id="membershipActiveEngagement"
            /> */}
            <SubmitButton isSubmitting={isSubmitting}>Lagre valg</SubmitButton>
          </Form>
        )}
      </Formik>
    </div>
  );
}

RegistrationComplete.propTypes = {};

export default RegistrationComplete;
