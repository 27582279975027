// Disabling eslint rules to document errors
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
import axios from 'axios';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  CheckboxFormik,
  FormikExpandedForm,
} from 'SharedFrontend/src/Components/Forms/Formik';
import { generalUtils } from 'SharedFrontend/src/Utils';
import ValidationErrorFormik from '../../UI/forms/formik/ValidationErrorFormik';
import FormSection from '../../UI/forms/FormSection';
import autosuggestSelect from '../autosuggestSelect';
import SemesterPicker from '../semesterPicker';
import ErrorReporter from './errorReporter';
import InputWrapper from './inputWrapper';
import {
  getInitialValues,
  reservationsLabels,
  sendAnalytics,
  signupDefaultStrings,
  validationSchema,
} from './registrationFormHelpers';
import RegistrationInputField from './registrationInputField';
import RegistrationSection from './registrationSection';

const urlPostalCode = '/api/postnummer/check/'; // Postalcode lookup
const urlCountry = '/api/land/liste/'; // Country lookup

function RegistrationForm({
  formSections,
  options,
  selectedSection,
  showSummary,
  status,
  values,
}) {
  const [isSent, setIsSent] = useState(false);

  const { reservationsSection, recruitmentSection } = formSections;

  const scrollToSection = (category) => {
    if (category) {
      document.querySelector(`#${category}`).scrollIntoView();
    }
  };

  useEffect(() => {
    scrollToSection(selectedSection);
  }, [selectedSection]);

  const getPostalCodePlace = (code) =>
    code ? axios.get(urlPostalCode + code) : null;

  /**
   * In order to do our custom pushValidationErrors we have
   * to validate the form "manually" and check for any errors
   * that we can send to the pushValidationErrors function
   */
  async function submitForm(submitValues) {
    setIsSent(true);
    sendAnalytics('Button click', 'Oppsummering og innsending');
    showSummary(submitValues);
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={submitForm}
      initialValues={{ ...getInitialValues(), ...values }}
      validationSchema={validationSchema}
    >
      {({ values, errors, setFieldValue }) => (
        <FormikExpandedForm>
          <ErrorReporter />
          {recruitmentSection && (
            <RegistrationSection
              sectionData={recruitmentSection}
              defaultData={signupDefaultStrings.recruit}
              id="recruitment"
            >
              {recruitmentSection.text && (
                <div
                  className="vspace--medium"
                  dangerouslySetInnerHTML={generalUtils.createMarkup(
                    recruitmentSection.text
                  )}
                />
              )}
              <RegistrationInputField
                label={signupDefaultStrings.recruit.inputLabel}
                fieldName="recruiterPhone"
              />
            </RegistrationSection>
          )}
          <FormSection title="Medlemskap og utdanning" id="membership">
            <InputWrapper
              inputId="membership.schoolId"
              label="Studiested"
              isRequired
              className="select-wrapper"
            >
              <Field
                as="select"
                name="membership.schoolId"
                onChange={(e) => {
                  setFieldValue(
                    'membership.school',
                    e.target.options[e.target.selectedIndex].text
                  );
                  setFieldValue('membership.schoolId', e.target.value);
                }}
                onFocus={() => {
                  sendAnalytics('Field focus', 'Studiested');
                }}
              >
                <option value="">Velg fra listen</option>
                {options.school &&
                  options.school.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.shortname}
                    </option>
                  ))}
              </Field>
              <ValidationErrorFormik fieldName="membership.schoolId" />
            </InputWrapper>
            <input
              type="hidden"
              id="membership_school"
              name="membership.school"
              value=""
            />
            <InputWrapper
              inputId="membership.schoolId"
              label="Utdanning"
              isRequired
              className="select-wrapper"
            >
              <Field
                component="select"
                name="membership.studentEducationId"
                value={values.membership.studentEducationId}
                onChange={(value) => {
                  setFieldValue(
                    'membership.studentEducation',
                    value.target.options[value.target.selectedIndex].text
                  );
                  setFieldValue(
                    'membership.studentEducationId',
                    value.target.value
                  );
                }}
                onFocus={() => {
                  sendAnalytics('Field focus', 'Utdanning');
                }}
              >
                <option value="defaultEducation">Velg fra listen</option>
                {options.education &&
                  options.education.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.name}
                    </option>
                  ))}
              </Field>
              <ValidationErrorFormik fieldName="membership.studentEducationId" />
            </InputWrapper>
            <div className="semester-picker">
              <Field
                component={SemesterPicker}
                name="semester"
                value={{
                  semester: values.membership.graduationSemester,
                  year: values.membership.graduationYear,
                }}
                isSent={isSent}
                onFocus={() => {
                  sendAnalytics('Field focus', 'semesterPicker');
                }}
              />
            </div>
            <input
              type="hidden"
              id="personalia_countryId"
              name="personalia.countryId"
              value={values.personalia.countryId}
            />
            <p>
              <span className="mandatory">*</span>Felt markert med stjerne er
              obligatoriske
            </p>
          </FormSection>

          <FormSection title="Personopplysninger" id="personalia">
            <div>
              <RegistrationInputField
                label="Navn"
                fieldName="personalia.firstname"
                isRequired="true"
                onFocus={() => {
                  sendAnalytics('Field focus', 'Navn');
                }}
              />
              <RegistrationInputField
                label="Etternavn"
                fieldName="personalia.lastname"
                isRequired="true"
                onFocus={() => {
                  sendAnalytics('Field focus', 'Etternavn');
                }}
              />
              <div className="checkbox-wrapper">
                <CheckboxFormik
                  label="Har du adresse i utlandet?"
                  name="personalia.hasAddressAbroad"
                  onChangeSideEffect={(hasAddressAbroad) => {
                    setFieldValue(
                      'personalia.street',
                      hasAddressAbroad ? 'null' : ''
                    );
                    setFieldValue(
                      'personalia.streetNumber',
                      hasAddressAbroad ? 0 : ''
                    );
                    setFieldValue(
                      'personalia.zipCode',
                      hasAddressAbroad ? '0000' : ''
                    );
                    setFieldValue(
                      'personalia.city',
                      hasAddressAbroad ? 'null' : ''
                    );
                  }}
                />
              </div>
              {values.personalia.hasAddressAbroad && (
                <div>
                  <RegistrationInputField
                    label="Adresse (utlandet)"
                    component="textarea"
                    fieldName="personalia.addressAbroad"
                    onFocus={() => {
                      sendAnalytics('Field focus', 'Adresse (utlandet)');
                    }}
                  />
                  <InputWrapper
                    className="select-label"
                    label="Land"
                    inputId="personalia.country"
                  >
                    <Field
                      component={autosuggestSelect}
                      value={values.personalia.country}
                      onChange={(value) => {
                        setFieldValue('personalia.countryId', value.data.id);
                      }}
                      onFocus={() => {
                        sendAnalytics('Field focus', 'Land');
                      }}
                      name="personalia.country"
                      url={urlCountry}
                      dataValue="name"
                      dataName="name"
                      placeholder="Søk etter land"
                    />
                  </InputWrapper>
                  <input
                    type="hidden"
                    id="personalia_countryId"
                    name="personalia.countryId"
                    value={values.personalia.countryId}
                  />
                </div>
              )}
              {!values.personalia.hasAddressAbroad && (
                <div>
                  <div className="checkbox-wrapper">
                    <CheckboxFormik
                      label="Har du CO adresse?"
                      name="personalia.hasCoAddress"
                    />
                  </div>

                  {values.personalia.hasCoAddress && (
                    <RegistrationInputField
                      onFocus={() => {
                        sendAnalytics('Field focus', 'CO-adresse');
                      }}
                      label="CO-adresse"
                      fieldName="personalia.coAddress"
                      autocomplete="name"
                    />
                  )}
                  <RegistrationInputField
                    onFocus={() => {
                      sendAnalytics('Field focus', 'Gate/vei');
                    }}
                    label="Gate/vei"
                    fieldName="personalia.street"
                    autocomplete="street-address"
                    isRequired="true"
                  />
                  <RegistrationInputField
                    onFocus={() => {
                      sendAnalytics('Field focus', 'Nr.');
                    }}
                    label="Nr."
                    fieldName="personalia.streetNumber"
                    isRequired="true"
                    isNumeric
                  />
                  <RegistrationInputField
                    onFocus={() => {
                      sendAnalytics('Field focus', 'Oppgang');
                    }}
                    label="Oppgang"
                    fieldName="personalia.streetEntrance"
                  />

                  <div className="checkbox-wrapper">
                    <CheckboxFormik
                      label="Har du Postboks?"
                      name="personalia.hasPostbox"
                    />
                  </div>
                  {values.personalia.hasPostbox && (
                    <RegistrationInputField
                      onFocus={() => {
                        sendAnalytics('Field focus', 'Postboks');
                      }}
                      label="Postboks"
                      fieldName="personalia.postbox"
                    />
                  )}
                  <RegistrationInputField
                    onFocus={() => {
                      sendAnalytics('Field focus', 'Postnummer');
                    }}
                    label="Postnummer"
                    fieldName="personalia.zipCode"
                    isNumeric
                    onChange={(val) => {
                      getPostalCodePlace(val).then((response) => {
                        if (response.data) {
                          setFieldValue('personalia.city', response.data);
                        }
                      });
                    }}
                    isRequired="true"
                  />
                  <RegistrationInputField
                    onFocus={() => {
                      sendAnalytics('Field focus', 'Sted');
                    }}
                    label="Sted"
                    fieldName="personalia.city"
                    readOnly
                    isRequired="true"
                  />
                </div>
              )}
              <RegistrationInputField
                onFocus={() => {
                  sendAnalytics('Field focus', 'E-post');
                }}
                label="E-post"
                fieldName="personalia.email"
                isRequired="true"
              />
              <RegistrationInputField
                onFocus={() => {
                  sendAnalytics('Field focus', 'Telefonnummer');
                }}
                label="Telefonnummer"
                fieldName="personalia.mobile"
                isRequired="true"
                autocomplete="tel"
              />
              <RegistrationInputField
                onFocus={() => {
                  sendAnalytics('Field focus', 'Fødselsnummer (11 siffer)');
                }}
                label="Fødselsnummer (11 siffer)"
                fieldName="personalia.ssn"
                isRequired="true"
                isNumeric
              />
            </div>
            {status && status.msg && <div>{status.msg}</div>}
          </FormSection>
          <FormSection
            title={reservationsSection.title || 'Reservasjoner'}
            id="reservations"
          >
            {reservationsSection.text && (
              <div
                className="vspace--medium"
                dangerouslySetInnerHTML={generalUtils.createMarkup(
                  reservationsSection.text
                )}
              />
            )}
            <div className="form-section">
              <CheckboxFormik
                label={reservationsLabels.email}
                name="reservations.email"
              />
              <CheckboxFormik
                label={reservationsLabels.sms}
                name="reservations.sms"
              />
              <CheckboxFormik
                label={reservationsLabels.adsSoMe}
                name="reservations.adsSoMe"
              />
            </div>
          </FormSection>
          <button
            id="next-button"
            type="submit"
            className="button button--medium vspace--medium"
          >
            Neste side
          </button>
          {!!Object.keys(errors).length && isSent && (
            <div className="validation-msg">
              Et av feltene er ikke fylt ut riktig
            </div>
          )}
        </FormikExpandedForm>
      )}
    </Formik>
  );
}

export default RegistrationForm;
