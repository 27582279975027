import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { pushValidationErrors } from './registrationFormHelpers';

/**
 * Formik doesn't have a way to call a function after a submit has been sent,
 * and errors have been found. Instead we have to manually track submit count
 * and push validation errors when there are errors
 * https://github.com/formium/formik/issues/1484#issuecomment-657047521
 */
function ErrorReporter() {
  const { errors, submitCount, isSubmitting, isValid } = useFormikContext();
  const [lastHandled, setLastHandled] = useState(0);

  useEffect(() => {
    if (!isSubmitting && submitCount > lastHandled && !isValid) {
      pushValidationErrors(errors);
      setLastHandled(submitCount);
    }
  }, [submitCount, errors, isValid, isSubmitting]);

  return null;
}

ErrorReporter.propTypes = {};

export default ErrorReporter;
