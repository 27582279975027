import fetch from 'cross-fetch'; // It's important that we use isomorphic fetch, since the code can be run both server side and client side

const API_URL = '';

const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': API_URL,
};

const cleanPath = path => path.replace('//', '/');

/**
 * Creates a path by checking if a raw endpoint was passed, if not, make sure we clean the path and return it.
 * @param {string} endpoint An endpoint without leading /api/, used for most endpoints defined in the frontend project
 * @param {boolean} usePrefix Episerver will most of the time pass endpoints with the leading /api/, so we can tell makePath not to prefix with it
 */
const makePath = (endpoint, usePrefix) => {
    const prefix = usePrefix ? '/api/' : '';
    return cleanPath(`${API_URL}${prefix}${endpoint}`);
};

const isJsonResponse = response => {
    const contentType = response.headers.get('content-type');
    return contentType && contentType.indexOf('application/json') !== -1;
};

const requestData = (method, bodyData = null, customHeaders = null) => ({
    method,
    headers: customHeaders || headers,
    mode: 'cors',
    body: bodyData ? JSON.stringify(bodyData) : null,
});

export function isError(response) {
    if (typeof response.ok !== 'undefined' && !response.ok) {
        return true;
    }

    if (response.hasError === true) {
        return true;
    }

    // We attach the status to the parsed object json object in handleResponse, to simplify checking status code later
    const status = response === 'number' ? response : response.responseStatus;

    if (typeof status === 'number' && status >= 300) {
        return true;
    }
    return false;
}

async function handleResponse(response) {
    const responseError = isError(response);
    if (isJsonResponse(response)) {
        const content = await response.json();
        if (responseError) {
            return {
                content: content,
                isError: responseError,
                responseStatus: response.status,
            };
        }
        return content;
    }
    return response;
}

export async function getData(endpoint, usePrefix = true) {
    const response = await fetch(
        makePath(endpoint, usePrefix),
        requestData('GET')
    );
    return handleResponse(response);
}

export async function postData(
    endpoint,
    data = {},
    xsrfToken = null,
    usePrefix = true
) {
    const customHeaders = headers;
    if (xsrfToken) {
        customHeaders['X-XSRF-Token'] = xsrfToken;
    }
    const response = await fetch(
        makePath(endpoint, usePrefix),
        requestData('POST', data, customHeaders)
    );
    return handleResponse(response);
}

export async function putData(endpoint, data = {}, usePrefix = true) {
    const response = await fetch(
        makePath(endpoint, usePrefix),
        requestData('PUT', data)
    );
    return handleResponse(response);
}

export async function deleteData(endpoint, data = {}, usePrefix = true) {
    const response = await fetch(
        makePath(endpoint, usePrefix),
        requestData('DELETE', data)
    );
    return handleResponse(response);
}

export function hasValidationErrors(response) {
    return response.Message && response.Message === 'The request is invalid.';
}

export function hasFileSizeError(modelStateField) {
    if (modelStateField && modelStateField.length > 0) {
        return modelStateField.some(error => {
            return error.startsWith('Filesize is');
        });
    }
    return false;
}

export async function getMessage(response) {
    const responseContent = response.content || response;
    if (responseContent.message || responseContent.Message) {
        return responseContent.message || responseContent.Message;
    }
    if (isJsonResponse(response)) {
        const jsonResponse = await response.json();
        if (jsonResponse.message !== undefined) {
            return jsonResponse.message;
        }
    }
    return undefined;
}

export default {
    getData,
    postData,
    putData,
    deleteData,
    isError,
    hasValidationErrors,
    hasFileSizeError,
    getMessage,
};
