import React from 'react';
import PropTypes from 'prop-types';

const Chevron = ({ className, active }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 13"
        className={className}
        style={{
            transition: 'ease-in-out transform 0.1s',
        }}
    >
        <path
            fillRule="nonzero"
            d="M22.4 0a1 1 0 0 1 1 1l-.3.6-11.3 10.7a1 1 0 0 1-.6.3 1 1 0 0 1-.7-.3L.3 1.6A1 1 0 0 1 0 1a1 1 0 0 1 1-1c.2 0 .5 0 .6.3l9.6 10L21.7.3c.2-.2.4-.3.7-.3"
        />
    </svg>
);

Chevron.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool.isRequired,
};

Chevron.defaultProps = {
    className: '',
};

export default Chevron;
