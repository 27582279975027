import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import Radio from '../../Input/Radio/Radio';

const RadioFormik = props => {
    const { name, value, onChange } = props;
    const [field, meta, helpers] = useField({ name, value, type: 'radio' });

    return (
        <Radio
            {...props}
            checked={field.checked}
            value={field.value}
            onChange={e => {
                helpers.setValue(e.target.value);
                if (onChange) {
                    onChange(e.target.value, e.target.name);
                }
            }}
            onBlur={() => helpers.setTouched(true)}
        />
    );
};

RadioFormik.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default RadioFormik;
