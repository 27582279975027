import React from 'react';
import PropTypes from 'prop-types';
import { BaseLink } from '@utdanningsforbundet/sharedfrontend';

import { removeSoftHyphen } from 'SharedFrontend/src/Utils/generalUtils';

const LocalNavigation = ({ menuItems }) => (
    <div className="main-container">
        <ul>
            {menuItems.map((item) => {
                const { linkText, linkUrl, target } = item.link;

                return (
                    <li key={linkUrl}>
                        <BaseLink target={target} href={linkUrl}>
                            <span className={item.selected ? 'selected' : ''}>
                                {removeSoftHyphen(linkText)}
                            </span>
                        </BaseLink>
                    </li>
                );
            })}
        </ul>
    </div>
);

LocalNavigation.propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LocalNavigation;
