/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

function BaseButton({
  children,
  className,
  id,
  type,
  onClick,
  labelledBy,
  tabIndex,
  ariaControls,
}) {
  return (
    <button
      id={id}
      aria-labelledby={labelledBy}
      type={type}
      onClick={onClick}
      className={className}
      tabIndex={tabIndex}
      aria-controls={ariaControls}
    >
      {children}
    </button>
  );
}

BaseButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['button', 'submit']),
  tabIndex: PropTypes.oneOf([-1, null]),
};

BaseButton.defaultProps = {
  type: 'button',
  tabIndex: null,
};

export default BaseButton;
