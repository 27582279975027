import React, { useState } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import Add2Calendar from 'add2calendar';

import classNames from 'classnames';

const AddToCalendar = ({ calendarData, buttonElement, className }) => {
    if (!calendarData) {
        return null;
    }
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [calendarEvent, setCalendarEvent] = useState(() => {
        const {
            arrangementStart,
            arrangementStop,
            title,
            intro,
            arrangementAddress,
        } = calendarData;
        if (!arrangementStart) {
            return null;
        }
        return new Add2Calendar({
            title,
            start: arrangementStart,
            end: arrangementStop || arrangementStart,
            location: arrangementAddress,
            description: intro,
            isAllDay: false,
        });
    });

    const links = calendarEvent
        ? [
              { href: calendarEvent.getICalUrl(), label: 'iCalendar' },
              { href: calendarEvent.getGoogleUrl(), label: 'Google Calendar' },
              { href: calendarEvent.getOutlookUrl(), label: 'Outlook' },
          ]
        : [];

    const linkElements = links.map(link => (
        <li className="atcb-item" key={links.label}>
            <a
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                className="atcb-item-link"
            >
                {link.label}
            </a>
        </li>
    ));

    const calendarButton = React.cloneElement(buttonElement, {
        onClick: () => setCalendarOpen(!calendarOpen),
        className: classNames('atcb-link', 'calendar', {
            active: calendarOpen,
        }),
    });

    if (linkElements.length === 0) {
        return null;
    }

    return (
        <div className={classNames('category', 'calendar-add-to', className)}>
            <span
                className="addtocalendar"
                data-calendars="iCalendar, Google Calendar, Outlook, Outlook Online"
            >
                {calendarButton}
                {calendarOpen && (
                    <ul className="atcb-list toggled">{linkElements}</ul>
                )}
            </span>
        </div>
    );
};

export const calendarDataShape = PropTypes.shape({
    arrangementStart:
        PropTypes.oneOfType[
            (PropTypes.instanceOf(Date), PropTypes.instanceOf(dayjs))
        ],
    arrangementStop:
        PropTypes.oneOfType[
            (PropTypes.instanceOf(Date), PropTypes.instanceOf(dayjs))
        ],
    title: PropTypes.string,
    intro: PropTypes.string,
    arrangementAddress: PropTypes.string,
});

AddToCalendar.propTypes = {
    calendarData: calendarDataShape,
    buttonElement: PropTypes.element.isRequired,
    className: PropTypes.string,
};

AddToCalendar.defaultProps = {
    calendarData: null,
    className: null,
};

export default AddToCalendar;
