import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = ({ className, href, children }) => (
    <a
        className={className}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
    >
        {children}
    </a>
);

ExternalLink.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

ExternalLink.defaultProps = {
    className: '',
};

export default ExternalLink;
