import React from 'react';

import BaseInputFormik from '../BaseInputFormik/BaseInputFormik';

/**
 * This is intended for numeric inputs that aren't strictly numbers
 * like postcode
 * @param {*} props
 */
const NumericInputFormik = props => (
    <BaseInputFormik
        {...props}
        type="text"
        pattern="[0-9]*"
        inputMode="numeric"
    />
);

export default NumericInputFormik;
