export const regex = {
    /**
     * Matches + or 00 at the start, then if it's Danish or Norwegian (45, 47) accepts 8 digits
     * If it's Swedish (46) accepts between 8 and 10 digits
     */
    phoneScandinavia: /^((\+|00)(45|47))?[\d]{8}$|^((\+|00)46)[\d]{8,10}$/,
    phoneNo: /^((\+|00)47)?[\d]{8}$/,
};

export default { regex };
