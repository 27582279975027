import { initializeShared } from 'SharedFrontend';

import '@babel/polyfill';
import ReactModal from 'react-modal';
import '../css/master.scss';
import './config';
import Components from './components';
import footnote from './vanilla/footnote';

initializeShared();

class MyApp {
    constructor() {
        if (typeof ga !== 'undefined') {
            // eslint-disable-line no-undef
            ga('create', 'UA-91338133-1', 'auto'); // eslint-disable-line no-undef
        }
    }
}

// Always export a 'new' instance so it immediately evokes:
export default new MyApp();
