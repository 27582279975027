import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import BaseButton from 'UI/buttons/BaseButton';
import FullScreenModal from 'UI/FullScreenModal/FullScreenModal';
import { createMarkup } from 'Helpers/helpers';

function TableBlock({
    children,
    tableElement,
    showActions,
    className,
    useStickyHeader,
}) {
    const [modalActive, setModalActive] = useState(false);
    const table = tableElement ? (
        <div
            className="table-wrapper"
            dangerouslySetInnerHTML={createMarkup(tableElement)}
        />
    ) : (
        <div className="table-wrapper">{children}</div>
    );

    return (
        <div className={`table-block ${className || ''}`}>
            {showActions && (
                <div className="header-buttons">
                    <BaseButton
                        className="btn table-fullscreen-btn top"
                        onClick={() => setModalActive(!modalActive)}
                    >
                        Klikk for å se hele tabellen
                    </BaseButton>
                </div>
            )}
            {table}
            <FullScreenModal
                className={`table-block-modal${
                    useStickyHeader ? ' use-sticky-header' : ''
                }`}
                active={modalActive}
                setActive={setModalActive}
            >
                {table}
            </FullScreenModal>
        </div>
    );
}

TableBlock.propTypes = {
    tableElement: PropTypes.instanceOf(<table />).isRequired,
    useStickyHeader: PropTypes.bool,
    showActions: PropTypes.bool,
};
TableBlock.defaultProps = {
    useStickyHeader: false,
    showActions: true,
};

export default TableBlock;
