import React from 'react';
import PropTypes from 'prop-types';

import SharedModal from 'SharedFrontend/src/Components/UI/FullScreenModal/FullScreenModal';
import BaseButton from 'UI/buttons/BaseButton';

/**
 * To make sure our modals are accessible for screen readers etc.
 * we're using the react-modal package, that takes care of
 * setting the main wrapper tag to aria-hidden, and rendering
 * the modal outside of that.
 */
const FullScreenModal = props => {
    const { active, setActive, useCloseButton } = props;

    const closeButton = useCloseButton ? (
        <BaseButton
            ariaLabel="Lukk vindu"
            className="modal-close-button"
            onClick={() => setActive(!active)}
        />
    ) : null;

    return <SharedModal {...props} closeButton={closeButton} />;
};

FullScreenModal.propTypes = {
    active: PropTypes.bool.isRequired,
    setActive: PropTypes.func.isRequired,
    useCloseButton: PropTypes.bool,
};

FullScreenModal.defaultProps = {
    useCloseButton: true,
};

export default FullScreenModal;
