import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * A container that can be used to animate visibility as well
 * as actually not rendering the children after the animation is finished
 * Requires that the component is given a transition in CSS
 * Uses two different ways of toggling visually and actually hidden content
 * If hidden and going to show it renders children, then waits 20ms
 * before removing the "visually hidden" class
 * If not hidden and going to hide it adds the "visually hidden" class first
 * and then sets display:none after the transition has ended
 * @param {string} [className] - className added to wrapper component
 * @param {node} [children] - will appear in the accordion-section
 * @param {boolean} [shouldHide = false] - set if accordion should be open on load
 * @param {string} [visuallyHiddenClass = active] - name of the class to add when active
 * @param {string} [wrapperElement = div] - what type of DOM element to use as a wrapper
 */

const ToggleContainer = ({
    className,
    children,
    shouldHide,
    visuallyHiddenClass,
    id,
    wrapperElement,
}) => {
    const [visuallyHidden, setVisuallyHidden] = useState(shouldHide);
    /** If we set the wrapper to display: none at the beginning, any
     * effects within the wrapper that depends on the actual DOM won't work
     * (like element.scrollHeight in Accordion). So we start it off as just
     * visually hidden, then do the actual hiding after 20ms
     */
    const [hidden, setHidden] = useState(false);
    const [timer, setTimer] = useState(null);

    const WrapperElement = wrapperElement;
    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        if (!shouldHide && hidden) {
            setHidden(false);
            const newTimer = setTimeout(() => {
                setVisuallyHidden(false);
            }, 20);
            setTimer(newTimer);
        } else if (shouldHide && !visuallyHidden) {
            setVisuallyHidden(true);
        } else if (shouldHide && visuallyHidden && !hidden) {
            /**
             * If visuallyHidden and hidden is out of sync, we set
             * hidden to true after 20ms. This should only happen at
             * the very start of the component life
             */
            const newTimer = setTimeout(() => {
                setHidden(true);
            }, 20);
            setTimer(newTimer);
        }
    }, [shouldHide]);

    const classNames = [
        className || '',
        visuallyHidden ? visuallyHiddenClass : '',
    ];

    /** In case the children need to know whether they're currently "visually hidden"
     * This is useful for setting tabIndex to -1 before the javascript has properly loaded in the client
     * (Mostly a fix for SiteImprove reporting hidden element can gain focus)
     */
    const clonedChildren = hidden
        ? null
        : React.Children.map(children, child =>
              React.cloneElement(child, { visuallyHidden })
          );

    return (
        <WrapperElement
            id={id}
            onTransitionEnd={() => {
                setHidden(visuallyHidden);
            }}
            aria-hidden={visuallyHidden}
            className={classNames.join(' ')}
        >
            {clonedChildren}
        </WrapperElement>
    );
};

ToggleContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    id: PropTypes.string,
    shouldHide: PropTypes.bool,
    visuallyHiddenClass: PropTypes.string,
    wrapperElement: PropTypes.string,
};

ToggleContainer.defaultProps = {
    className: null,
    children: null,
    id: null,
    shouldHide: false,
    visuallyHiddenClass: 'hidden',
    wrapperElement: 'div',
};

export default ToggleContainer;
