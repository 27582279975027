import React from 'react';
import { Field, useField } from 'formik';
import InputWrapper from './inputWrapper';

function RegistrationInputField({
  readOnly,
  fieldName,
  label,
  isRequired,
  type,
  autocomplete,
  component,
  onChange,
  onFocus,
  isNumeric,
}) {
  const [field, meta, helpers] = useField({ name: fieldName });

  const { touched, error } = meta;

  const preventSubmitOnEnter = (event) => {
    if (event.which === 13) {
      event.preventDefault();
    }
  };

  const handleChange = (value) => {
    helpers.setValue(value);
    if (typeof onChange !== 'undefined') {
      onChange(value);
    }
  };

  const handleFocus = (value) => {
    if (typeof onFocus !== 'undefined') {
      onFocus(value);
    }
  };

  return (
    <InputWrapper
      readOnly={readOnly}
      label={label}
      fieldId={''}
      isRequired={isRequired}
      touched={touched}
      error={error}
    >
      <Field
        type={type || 'text'}
        component={component || 'input'}
        name={fieldName}
        onKeyPress={preventSubmitOnEnter}
        autoComplete={autocomplete || ''}
        onChange={(event) => {
          handleChange(event.target.value);
        }}
        readOnly={readOnly}
        onFocus={(event) => {
          handleFocus(event.target.value);
        }}
        inputMode={isNumeric ? 'numeric' : null}
        pattern={isNumeric ? '[0-9]*' : null}
      />
    </InputWrapper>
  );
}

export default RegistrationInputField;
