import React from 'react';
import PropTypes from 'prop-types';

function SummaryItem({ label, children, className, wrapperElement }) {
  const Wrapper = wrapperElement;
  return (
    <Wrapper className={className}>
      <strong>{label}:</strong> {children}
    </Wrapper>
  );
}

SummaryItem.propTypes = {
  wrapperElement: PropTypes.oneOf(['li', 'div', 'span']),
};

SummaryItem.defaultProps = {
  wrapperElement: 'li',
};

export default SummaryItem;
