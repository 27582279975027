import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import InputInfo from '../../UI/InputInfo/InputInfo';
import InputLabelText from '../../UI/InputLabelText/InputLabelText';
import InputWrapper from '../../UI/InputWrapper/InputWrapper';
import generalUtils from '../../../../Utils/generalUtils';

const BaseInput = React.forwardRef(
    (
        {
            ariaLabel,
            autocomplete,
            className,
            customInputProps,
            disabled,
            errorMessage,
            inputElement,
            inputId,
            inputIdPrefix,
            inputInfo,
            inputMode,
            label,
            labelScreenReaderOnly,
            maxLength,
            name,
            onBlur,
            onChange,
            pattern,
            placeholder,
            prefix,
            readOnly,
            required,
            type,
            valid,
            value,
        },
        ref
    ) => {
        const InputElement = inputElement || 'input';

        const htmlID = inputIdPrefix
            ? generalUtils.createValidID(inputIdPrefix, inputId)
            : inputId;

        return (
            <InputWrapper
                className={classNames(
                    {
                        'with-prefix': !!prefix,
                    },
                    className
                )}
            >
                <label className="input__label" htmlFor={htmlID}>
                    <InputLabelText
                        screenReaderOnly={labelScreenReaderOnly}
                        required={required}
                    >
                        {label}
                    </InputLabelText>
                    <div className="input__content">
                        {prefix && <span className="prefix">{prefix}</span>}
                        <InputElement
                            aria-invalid={valid !== undefined ? !valid : false}
                            aria-label={ariaLabel}
                            autoComplete={autocomplete || 'on'}
                            className={classNames({
                                disabled: readOnly,
                            })}
                            disabled={disabled}
                            id={htmlID}
                            inputMode={inputMode}
                            maxLength={maxLength}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            pattern={pattern}
                            placeholder={placeholder}
                            readOnly={readOnly}
                            ref={ref}
                            required={required}
                            type={type}
                            value={value}
                            {...customInputProps}
                        />
                    </div>
                    <InputInfo error={errorMessage} info={inputInfo} />
                </label>
            </InputWrapper>
        );
    }
);

BaseInput.displayName = 'BaseInput';

BaseInput.propTypes = {
    ariaLabel: PropTypes.string,
    autocomplete: PropTypes.string,
    className: PropTypes.string,
    customInputProps: PropTypes.object,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    inputElement: PropTypes.string,
    inputId: PropTypes.string,
    inputIdPrefix: PropTypes.string,
    inputInfo: PropTypes.string,
    inputMode: PropTypes.oneOf([
        null,
        'text',
        'decimal',
        'numeric',
        'tel',
        'search',
        'email',
        'url',
    ]),
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    prefix: PropTypes.node,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    type: PropTypes.string,
    valid: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
    ]),
    labelScreenReaderOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    pattern: PropTypes.string,
};

BaseInput.defaultProps = {
    ariaLabel: null,
    autocomplete: undefined,
    className: null,
    customInputProps: null,
    disabled: false,
    errorMessage: undefined,
    inputElement: 'input',
    inputId: null,
    inputIdPrefix: null,
    inputInfo: undefined,
    inputMode: null,
    label: '',
    onBlur: null,
    onChange: null,
    placeholder: null,
    prefix: null,
    readOnly: false,
    required: null,
    type: 'text',
    valid: undefined,
    value: '',
};

export default BaseInput;
