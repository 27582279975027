import { apiUtils } from 'SharedFrontend/src/Utils';

export async function postEnrollment(data, antiForgeryToken) {
  const response = await apiUtils.postData(
    'person/pedstud-enroll',
    data,
    antiForgeryToken
  );
  return response;
}

export async function postEnrollmentUpdate(webId, data, antiForgeryToken) {
  const response = await apiUtils.postData(
    `person/pedstud-enroll-update/${webId}`,
    data,
    antiForgeryToken
  );
  return response;
}
