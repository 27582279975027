import react, { useState, useEffect } from 'react';

const defaultTabletSize = 768;

export function useMediaMatch(mediaQuery) {
    const [isMatch, setIsMatch] = useState(null);
    useEffect(() => {
        const onMatchChange = e => {
            setIsMatch(e.matches);
        };
        let match = null;
        if (typeof window !== 'undefined') {
            match = window.matchMedia(mediaQuery);
            setIsMatch(match.matches);
            match.addListener(onMatchChange);
        }
        return () => {
            if (match) {
                match.removeListener(onMatchChange);
            }
        };
    }, []);

    return isMatch;
}

export function useMobileSize(mobileSize = '480px') {
    const isMobileSize = useMediaMatch(`(max-width: ${mobileSize})`);
    return isMobileSize;
}

export function useBelowTabletSize(tabletSize = defaultTabletSize - 1) {
    const isMobileSize = useMediaMatch(`(max-width: ${tabletSize}px)`);
    return isMobileSize;
}

export function useAboveTabletSize(tabletSize = defaultTabletSize) {
    const isMobileSize = useMediaMatch(`(min-width: ${tabletSize}px)`);
    return isMobileSize;
}

export default { useMobileSize, useBelowTabletSize, useAboveTabletSize };
