import React, { useEffect } from 'react';

/**
 * Adds event listeners to the ref'd element that triggers similarly to css :hover and :focus
 * @param {ref} elementRef  ref to the element we're listening to
 * @param {function} onGainFocus function to trigger when we gain focus or hover
 * @param {function} onLoseFocus function to trigger when we lose focus or hover
 * @param {bool} bubbleEvents if you need to have the events "bubble" i.e. have the event trigger
 * on for example child components, we need to add different events.
 */
export default function useHoverFocus(
    elementRef,
    onGainFocus,
    onLoseFocus,
    bubbleEvents = false,
    isActive = true
) {
    useEffect(() => {
        if (isActive && elementRef && elementRef.current) {
            const { current } = elementRef;
            current.addEventListener(
                bubbleEvents ? 'mouseover' : 'mouseenter',
                onGainFocus
            );
            current.addEventListener(
                bubbleEvents ? 'mouseout' : 'mouseleave',
                onLoseFocus
            );
            current.addEventListener(
                bubbleEvents ? 'focusin' : 'focus',
                onGainFocus
            );
            current.addEventListener(
                bubbleEvents ? 'focusout' : 'blur',
                onLoseFocus
            );
        }
        return () => {
            if (elementRef && elementRef.current) {
                const { current } = elementRef;
                current.removeEventListener(
                    bubbleEvents ? 'mouseover' : 'mouseenter',
                    onGainFocus
                );
                current.removeEventListener(
                    bubbleEvents ? 'mouseout' : 'mouseleave',
                    onLoseFocus
                );
                current.removeEventListener(
                    bubbleEvents ? 'focusin' : 'focus',
                    onGainFocus
                );
                current.removeEventListener(
                    bubbleEvents ? 'focusout' : 'blur',
                    onLoseFocus
                );
            }
        };
    }, [elementRef, isActive]);
}
