import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useUniqueId from '../../Hooks/useUniqueId';
import {
    CollapseExpandClosed,
    CollapseExpandOpened,
} from '../../Utils/analyticsUtils';
import Chevron from './Chevron/Chevron';
import Cross from './Cross/Cross';
import { getHeaderTag } from '../../Utils/enums';
import { accordionToggle, unSubScribe } from '../../Events/events';
import Header from '../Header/Header';

/**
 * @param {string} [className] - available classNames using BEM(className = *): *, *__button, *__title, *__contents, *__icon
 * @param {string} [title] - will appear as the accordion/button title
 * @param {node} [children] - will appear in the accordion-section
 * @param {number} [headerSize] - follows episerver header size strings
 * @param {boolean} [withIcon = false] - if the svg icon should be shown or not
 * @param {string} [iconStyle = "chevron"] - string with icon type, "chevron" or "cross"
 * @param {boolean} [isOpen = false] - set if accordion should be open on load, it will also update the state of the accordion if it changes
 * so it can be used to control whether the accordion is open or not from the outside
 * @param {boolean} [withPadding = false] - should component have padding class "with-padding" or not
 * @param {function} [onToggle = undefined] - callback function for when the accordion is toggled
 * @param {bool} [useEventToggle = false] - should component subscribe to toggle accordion event or not
 * @param {bool} [useOnClickEvent = true] - should component use button click event or only be controlled from the outside
 */

const defaultTransitionTime = 600;
const getAccordionContentStyle = (
    height,
    closing,
    transitionTime = defaultTransitionTime
) => {
    return {
        maxHeight: height,
        padding: closing ? 0 : null,
        overflow: 'hidden',
        transition: `${transitionTime}ms ease`,
        transitionProperty: 'padding, max-height',
    };
};

const Accordion = ({
    className,
    title,
    titleComponent,
    anchorLink,
    children,
    headerSize,
    useEpiHeaderTag,
    withIcon,
    iconStyle,
    isOpen,
    withPadding,
    onToggle,
    useEventToggle,
    transitionTime,
    useOnClickEvent,
    usePreview,
    previewHeight,
    openButtonId,
}) => {
    const [active, setActive] = useState(true); // We always start active, so we can measure the content
    const [closing, setClosing] = useState(false);
    const [opening, setOpening] = useState(false);

    const deactivatedHeight = usePreview ? previewHeight : '0px';

    const [height, setHeight] = useState(isOpen ? null : deactivatedHeight);
    const contentRef = useRef(null);
    const timer = useRef(null);

    const onActivateEnd = () => {
        setHeight(null);
        setOpening(false);
    };

    const onDeactivateEnd = () => {
        setActive(false);
        setClosing(false);
        setHeight(deactivatedHeight);
        if (typeof onToggle === 'function') {
            onToggle(false);
        }
    };

    const setTransitionEnd = transitionEndFunction => {
        clearTimeout(timer.current);
        timer.current = setTimeout(transitionEndFunction, transitionTime);
    };

    const activate = () => {
        setActive(true);
        setOpening(true);
        setClosing(false);
        setTransitionEnd(onActivateEnd);
        if (typeof onToggle === 'function') {
            onToggle(true);
        }
    };

    const deactivate = () => {
        setHeight(`${contentRef.current.scrollHeight}px`);
        setClosing(true);
        setOpening(false);
        setTransitionEnd(onDeactivateEnd);
    };

    const toggleAccordion = () => {
        if (useOnClickEvent) {
            setOpening(false);
            setClosing(false);
            if (active && !closing) {
                deactivate();
                CollapseExpandClosed(title);
            } else {
                activate();
                CollapseExpandOpened(title);
            }
        }
    };

    useEffect(() => {
        if (isOpen && !active) {
            activate();
        } else if (!isOpen && active) {
            deactivate();
        }
    }, [isOpen]);

    useEffect(() => {
        let eventSubscription = null;
        if (useEventToggle) {
            eventSubscription = accordionToggle.subscribe(
                (msg, eventActivate) => {
                    if (eventActivate) {
                        activate();
                    } else {
                        deactivate();
                    }
                }
            );
        }
        return () => {
            clearTimeout(timer.current);
            if (useEventToggle) {
                unSubScribe(eventSubscription);
            }
        };
    }, []);

    useEffect(() => {
        if (active && closing) {
            setHeight(deactivatedHeight);
        } else if (active && opening) {
            setHeight(`${contentRef.current.scrollHeight}px`);
        }
    }, [active, height, opening, closing]);

    const headerTag = getHeaderTag(useEpiHeaderTag, headerSize);

    const accordionID = useUniqueId('accordion');
    return (
        <div
            id={anchorLink}
            className={classNames(
                'accordion',
                headerTag,
                { 'with-padding': withPadding, active: active },
                className
            )}
        >
            <div className="button-wrapper">
                <button
                    type="button"
                    onClick={toggleAccordion}
                    id={openButtonId || `${accordionID}`}
                    className={classNames('accordion__button', {
                        active: active,
                    })}
                    aria-expanded={active}
                    aria-controls={`accordion-section-${title}`}
                >
                    {titleComponent && (
                        <span className="accordion__title">
                            {titleComponent}
                        </span>
                    )}
                    {!titleComponent && (
                        <Header
                            headerSize={headerSize}
                            useEpiHeaderTag={useEpiHeaderTag}
                            className="accordion__title"
                        >
                            {title}
                        </Header>
                    )}
                    {withIcon && (
                        <div className="accordion__icon__icon--wrapper">
                            {iconStyle === 'chevron' ? (
                                <Chevron
                                    active={active}
                                    className="accordion__icon"
                                />
                            ) : (
                                <Cross
                                    active={active}
                                    className="accordion__icon"
                                />
                            )}
                        </div>
                    )}
                </button>
            </div>
            <div
                id={`accordion-section_${accordionID}`}
                ref={contentRef}
                className={classNames(
                    'accordion__contents',
                    {
                        active: active,
                    },
                    className
                )}
                style={getAccordionContentStyle(height, closing)}
            >
                {(active || usePreview) && (
                    <>
                        <div className="accordion__contents-inner">
                            {children}
                        </div>
                        {anchorLink && (
                            <a
                                className="anchor-link"
                                href={`#${anchorLink}`}
                            >{`Lenke til ${title}`}</a>
                        )}
                    </>
                )}
                <div className="accordion-overlay" />
            </div>
        </div>
    );
};

Accordion.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    titleComponent: PropTypes.node,
    anchorLink: PropTypes.string,
    children: PropTypes.node,
    withIcon: PropTypes.bool,
    iconStyle: PropTypes.string,
    isOpen: PropTypes.bool,
    headerSize: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
    useEpiHeaderTag: PropTypes.bool,
    withPadding: PropTypes.bool,
    onToggle: PropTypes.func,
    useEventToggle: PropTypes.bool,
    transitionTime: PropTypes.number,
    useOnClickEvent: PropTypes.bool,
    previewHeight: PropTypes.string,
    usePreview: PropTypes.bool,
    openButtonId: PropTypes.string,
};

Accordion.defaultProps = {
    className: '',
    children: '',
    anchorLink: null,
    withIcon: false,
    iconStyle: 'chevron',
    isOpen: false,
    headerSize: 0,
    useEpiHeaderTag: false,
    usePreview: false,
    previewHeight: '8rem',
    withPadding: false,
    onToggle: undefined,
    useEventToggle: false,
    transitionTime: defaultTransitionTime,
    useOnClickEvent: true,
    openButtonId: null,
    titleComponent: null,
};

export default Accordion;
