import React from 'react';
import PropTypes from 'prop-types';
import ValidationError from '../../UI/forms/ValidationError';

function InputWrapper({
  children,
  inputId,
  readOnly,
  label,
  isRequired,
  touched,
  error,
  className,
}) {
  return (
    <div className={`input-wrapper ${readOnly ? 'read-only' : ''} ${className || ''}`}>
      <label htmlFor={inputId}>
        <span className="input__label">
          {label}
          {isRequired && <span className="mandatory"> *</span>}
        </span>
        {children}
        <ValidationError touched={touched} error={error} />
      </label>
    </div>
  );
}

InputWrapper.propTypes = {};

export default InputWrapper;
