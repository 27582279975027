import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Accordion from './Accordion';
import Header from '../Header/Header';

const ConditionalAccordion = props => {
    const {
        title,
        titleComponent,
        useCollapsible,
        wrapperId,
        headerSize,
        useEpiHeaderSizes,
        children,
        className,
        useEventToggle,
        titleClassName,
        notAccordionClassName,
    } = props;

    if (useCollapsible) {
        return (
            <Accordion
                {...props}
                useEventToggle={useEventToggle}
                headerSize={headerSize}
                useEpiHeaderSizes
                useEpiHeaderTag={useEpiHeaderSizes}
            >
                {children}
            </Accordion>
        );
    }

    return (
        <div
            id={wrapperId}
            className={classNames(className, notAccordionClassName)}
        >
            {titleComponent && (
                <span className={titleClassName}>{titleComponent}</span>
            )}

            {!titleComponent && (
                <Header
                    headerSize={headerSize}
                    useEpiHeaderTag={useEpiHeaderSizes}
                    className={titleClassName}
                >
                    {title}
                </Header>
            )}

            {children}
        </div>
    );
};

ConditionalAccordion.propTypes = {
    headerSize: PropTypes.oneOf([0, 1, 2, 3, 4]),
    title: PropTypes.string,
    titleComponent: PropTypes.node,
    useCollapsible: PropTypes.bool,
    wrapperId: PropTypes.string,
    useEpiHeaderSizes: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    useEventToggle: PropTypes.bool,
    titleClassName: PropTypes.string,
    notAccordionClassName: PropTypes.string,
};

ConditionalAccordion.defaultProps = {
    headerSize: 0,
};

export default ConditionalAccordion;
