import { isServerSide } from './renderUtils';

export function getCookieValue(name) {
    if (isServerSide()) {
        return '';
    }

    const value = document.cookie.match(`(^|[^;]+)\\s*${name}\\s*=\\s*([^;]+)`);
    return value ? value.pop() : '';
}

export function setCookieValue(
    name,
    value,
    expires,
    path = null,
    domain = null
) {
    if (isServerSide()) {
        return;
    }

    const pathString = path ? `path=${path};` : 'path=/;';
    const domainString = domain ? `domain=${domain};` : '';
    const valueString = value ? `${value};` : ';';
    const newCookie = `${name}=${valueString}${pathString}${domainString}expires=${expires}`;
    document.cookie = newCookie;
}

export function cookieExists(name) {
    if (isServerSide()) {
        return false;
    }

    return document.cookie
        .split(';')
        .some(item => item.trim().startsWith(name));
}

/**
 * Keep in mind that you're only allowed to delete cookies from "your own domain"
 * via Javascript. You cannot delete a cookie set to a domain when you're on a subdomain
 * i.e: If you're on dev-udf.udf.no you cannot delete a cookie with it's domain set to .udf.no
 * https://stackoverflow.com/questions/17776975/how-do-i-delete-a-cookie-from-a-specific-domain-using-javascript
 */
export function deleteCookie(name, path = null, domain = null) {
    if (window) {
        if (getCookieValue(name)) {
            setCookieValue(
                name,
                null,
                path,
                domain,
                'Thu, 01 Jan 1970 00:00:01 GMT'
            );
        }
    }
}

export default {
    getCookieValue,
    setCookieValue,
    deleteCookie,
    cookieExists,
};
