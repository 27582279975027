import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useEscapeKey } from 'SharedFrontend/src/Hooks';
import BaseButton from 'UI/buttons/BaseButton';

function HeaderSearch({ searchAction }) {
    const [isOpen, setIsOpen] = useState(false);

    useEscapeKey(() => setIsOpen(false), isOpen);

    const headerSearchId = 'header-search-section';
    return (
        <>
            <BaseButton
                className="header-search"
                onClick={() => setIsOpen(!isOpen)}
                ariaControls={headerSearchId}
            >
                Søk
            </BaseButton>
            <div
                className={`search-field-wrapper ${isOpen ? 'isOpen' : ''}`}
                id={headerSearchId}
                aria-expanded={isOpen}
            >
                <form action={searchAction} method="get">
                    <div className="search-field">
                        <label htmlFor="sok">
                            <div>Søk</div>
                            <input
                                name="sok"
                                id="sok"
                                type="text"
                                placeholder="Hva leter du etter?"
                                tabIndex={isOpen ? null : -1}
                            />
                        </label>
                        <BaseButton type="submit" tabIndex={isOpen ? null : -1}>
                            <span>Søk</span>
                        </BaseButton>
                    </div>
                </form>
            </div>
        </>
    );
}

HeaderSearch.propTypes = {
    searchAction: PropTypes.string.isRequired,
};

export default HeaderSearch;
