import React from 'react';
import PropTypes from 'prop-types';
import { generalUtils } from 'SharedFrontend/src/Utils';
import FormSection from '../../UI/forms/FormSection';

function RegistrationSection({ sectionData, defaultData, id, children }) {
  if (!sectionData && !defaultData) {
    return null;
  }
  const { title, text, headerSize } = sectionData || {};
  const { title: defaultTitle, text: defaultText } = defaultData || {};
  const textToUse = text || defaultText;
  return (
    <FormSection title={title || defaultTitle} headerSize={headerSize || 0} useEpiHeaderTag={true} id={id}>
      {textToUse && (
        <div
          className="vspace--medium editorial-content"
          dangerouslySetInnerHTML={generalUtils.createMarkup(textToUse)}
        />
      )}
      {children}
    </FormSection>
  );
}

RegistrationSection.propTypes = {};

export default RegistrationSection;
