import ReactModal from 'react-modal';
import 'dayjs/locale/nb';
import dayjs from 'dayjs';
import { isEpi } from './Utils/renderUtils';

export * from './Models';
export * from './Components';
export * from './Hooks';
export * from './Utils';
export * from './Events';
export * from './Vanilla';

// Required to be run by "parent" project
export function initializeShared() {
    dayjs.locale('nb'); // use Norwegian locale globally
    if (!isEpi()) {
        ReactModal.setAppElement('#main-wrapper');
    }
}
