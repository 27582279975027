import { validationUtils } from 'SharedFrontend/src/Utils';

export const messages = {
    // string: 'Skriv inn tekst uten tall og spesialtegn.',
    number: 'Skriv kun tall.',
    phone: 'Skriv inn et gyldig telefonnummer (åtte siffer).',
    email: 'Skriv inn en gyldig e-postadresse.',
    //  street: 'Skriv inn gyldig adresse.',
    //  houseNo: 'Skriv inn et gyldig husnummer og evt. oppgang.',
    zipcode: 'Skriv inn et gyldig postnummer (fire siffer).',
    ssn: 'Skriv inn et gyldig personnummer.',
    // date: 'Skriv inn gyldig dato (eksempel på gyldig dato er 01.01.2016).',
    // year: 'Skriv inn gyldig årstall (eksempel på gyldig årstall er 2016).',
    // child: 'Skriv inn riktig antall barn.',
    select: 'Du må angi utdanning.',
    positionPercent:
        'Stillingsprosent må være et tall mellom 1-100. Er det et desimal skal det separeres med punktum eller komma.',
    // optional: 'Dette feltet er valgfritt.',
    // maxLength: 'Du har oversteget grensen for antall tegn. Max 1000 tegn.',
    required: 'Dette feltet er obligatorisk.',
    registrationDate:
        'Vennligst skriv inn en dato som ikke er tidligere enn innmeldingsdato.',
    maxLength: max => `Maksimalt ${max} tegn.`,
    maxSize: max => `Nummeret kan ikke være høyere enn ${max}`,
    minSize: min => `Nummeret kan ikke være lavere enn ${min}`,
};

export const regex = {
    string: /^[a-zA-ZàáâäãåąčćęèéêëėæįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,
    number: /^[0-9]+$/,
    decimal: /^[0-9]+([,.][0-9]+)?$/g,
    phone: validationUtils.regex.phoneNo,
    // email: /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/,
    email: /^[a-zA-Z0-9.!#$%&amp;’*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    street: /^[0-9a-zA-Z ]+$/,
    houseNo: /^[0-9]{1,4}[a-zA-Z]{0,1}?$/,
    zipcode: /^[0-9]{4}$/,
    ssn: /^[0-9]{11}$/,
    date: /^[0-9.]{10}$/,
    year: /^[0-9]{4}$/,
    child: /^[0-9]{1,2}$/,
    select: /^(?!----$).*/,
    optional: /^.*$/,
    maxLength: length => {
        return new RegExp(`^.{0,${length}}$`);
    },
};

export function testPersonalNumber(value) {
    if (!value || value.length !== 11) {
        return false;
    }

    const digits = new Array(11);
    for (let i = 0; i < 11; i += 1) {
        digits[i] = parseInt(value.charAt(i), 10);
    }

    let dateDay = '';
    if (digits[0] > 3) dateDay += digits[0] - 4;
    else dateDay += digits[0];
    dateDay += digits[1];

    let dateMonth = '';
    if (digits[2] > 3) dateMonth += digits[2] - 4;
    else dateMonth += digits[2];
    dateMonth += digits[3];

    let dateYear = '';
    if (digits[4] > 3) dateYear += digits[4] - 4;
    else dateYear += digits[4];
    dateYear += digits[5];

    const thisYear = new Date().getFullYear().toString().substr(-2);

    if (parseInt(dateYear, 10) > parseInt(thisYear, 10))
        dateYear = `19${dateYear}`;
    else dateYear = `20${dateYear}`;

    const testDate = new Date(dateYear, dateMonth - 1, dateDay);
    if (testDate.getMonth() + 1 !== parseInt(dateMonth, 10)) return false;

    let k1 =
        (3 * digits[0] +
            7 * digits[1] +
            6 * digits[2] +
            digits[3] +
            8 * digits[4] +
            9 * digits[5] +
            4 * digits[6] +
            5 * digits[7] +
            2 * digits[8]) %
        11;
    // k1 = 11 - ((3�d1 + 7�d2 + 6�m1 + 1�m2 + 8��1 + 9��2 + 4�i1 + 5xi2 + 2�i3) mod 11),
    if (k1 > 0) k1 = 11 - k1;
    if (digits[9] !== k1) return false;

    let k2 =
        (5 * digits[0] +
            4 * digits[1] +
            3 * digits[2] +
            2 * digits[3] +
            7 * digits[4] +
            6 * digits[5] +
            5 * digits[6] +
            4 * digits[7] +
            3 * digits[8] +
            2 * digits[9]) %
        11;
    // k2 = 11 - ((5�d1 + 4�d2 + 3�m1 + 2�m2 + 7��1 + 6��2 + 5�i1 + 4�i2 + 3�i3 + 2�k1) mod 11).
    if (k2 > 0) k2 = 11 - k2;
    if (digits[10] !== k2) return false;

    return true;
}
export function testPositionPercent(value) {
    if (value === undefined || value === '') {
        return true;
    }
    const decimalRegex = new RegExp(/^[0-9]+([,.][0-9]+)?$/g);
    if (!decimalRegex.test(value)) {
        return false;
    }
    const parsedFloat = parseFloat(value.replace(',', '.'));
    if (parsedFloat < 1 || parsedFloat > 100) {
        return false;
    }
    return true;
}

export function phoneValidator(phoneNum) {
    if (!phoneNum) {
        return true;
    }
    const test = regex.phone.test(phoneNum.replaceAll(' ', ''));
    return test;
}
