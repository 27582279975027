import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BaseButton from './BaseButton';

const BurgerButton = ({
    menuID,
    menuOpen,
    onClick,
    className,
    menuLabel,
    children,
    ...props
}) => (
    <BaseButton
        {...props}
        ariaControls={menuID}
        ariaExpanded={menuOpen}
        onClick={() => onClick(!menuOpen)}
        className={classNames('burger-button', className)}
    >
        <span className={`linewrap ${menuOpen ? 'open' : 'closed'}`}>
            <span />
            <span />
            <span />
        </span>
        <span className="label-wrapper target-text">{menuLabel}</span>
        {children}
    </BaseButton>
);

BurgerButton.propTypes = {
    menuID: PropTypes.string.isRequired,
    menuOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    menuLabel: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
};

BurgerButton.defaultProps = {
    className: '',
    children: null,
};

export default BurgerButton;
