import React from 'react';
import PropTypes from 'prop-types';
import { apiUtils } from 'SharedFrontend/src/Utils';
import RegistrationSection from './registrationSection';

const defaultSuccessSectionData = { title: 'Vi har motattt dine endringer' };
const defaultErrorSectionData = {
  title: 'En feil har oppstått.',
  text: 'Medlemskapet ditt vil fortsatt bli registrert. Du kan gjøre endringer fra min side senere.',
};

function RegistrationUpdateReceipt({
  results,
  successSectionData,
  errorSectionData,
}) {
  if (!results) {
    return null;
  }
  if (apiUtils.isError(results)) {
    return (
      <RegistrationSection
        sectionData={errorSectionData}
        defaultData={defaultErrorSectionData}
      />
    );
  }
  return (
    <RegistrationSection
      sectionData={successSectionData}
      defaultData={defaultSuccessSectionData}
    />
  );
}

RegistrationUpdateReceipt.propTypes = {};

export default RegistrationUpdateReceipt;
