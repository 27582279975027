import React from 'react';
import PropTypes from 'prop-types';

function Bleed({ className }) {
  return <div className={`bleed ${className || ''}`} />;
}

Bleed.propTypes = {};

export default Bleed;
