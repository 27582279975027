import React from 'react';
import PropTypes from 'prop-types';

function ValidationError({ touched, error }) {
  if (!touched || !error) {
    return null;
  }

  return <div className="validation-msg has-error">{error}</div>;
}

ValidationError.propTypes = {};

export default ValidationError;
