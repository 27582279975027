import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import BaseInput from '../../Input/BaseInput/BaseInput';

const BaseInputFormik = props => {
    const { name, onChange } = props;
    const [field, meta, helpers] = useField({ name, type: 'checkbox' });

    return (
        <BaseInput
            {...props}
            value={field.value}
            onChange={e => {
                helpers.setValue(e.target.value);
                if (onChange) {
                    onChange(e.target.value, e.target.name);
                }
            }}
            errorMessage={meta.touched && meta.error ? meta.error : null}
            onBlur={field.onBlur}
        />
    );
};

BaseInputFormik.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

BaseInputFormik.defaultProps = {
    onChange: null,
};

export default BaseInputFormik;
