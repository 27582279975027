import React from 'react';
import PropTypes from 'prop-types';

import EpiLink from 'UI/Links/EpiLink';

const MenuItemList = ({ menuItems, className }) => {
    if (!menuItems || menuItems.length === 0) {
        return null;
    }

    return (
        <ul className={className}>
            {menuItems.map(menuItem => (
                <li key={menuItem.linkUrl}>
                    <EpiLink
                        linkModel={menuItem.link ? menuItem.link : menuItem}
                    />
                    {menuItem.children && (
                        <MenuItemList menuItems={menuItem.children} />
                    )}
                </li>
            ))}
        </ul>
    );
};

MenuItemList.propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    className: PropTypes.string,
};

export default MenuItemList;
