import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import Spinner from './spinner';

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion) {
  return (
    <span>{suggestion.name}</span>
  );
}
  
export default class AutosuggestSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || '',
      isLoading: false,
      fetchError: false,
      noResults: false,
      suggestions: [],
    };   
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }
  
  onChange(event, { newValue, method }){
    this.setState({
      value: newValue
    });
  }

  onKeyDown(event){
    if(event.which === 13) {
      event.preventDefault();
    }
  }

  onSuggestionsFetchRequested({ value }){
    if(this.props.url && value){
      this.setState({'isLoading':true});
      axios.get(this.props.url+value).then((response)=>{
        let selected = response.data.map((data)=>{
          return {
            'value': data[this.props.dataValue],
            'name': data[this.props.dataName],
            'data': data
          };
        });
        this.setState({
          'suggestions': selected,
          'isLoading': false,
          'noResults': !selected.length
        });
      }).catch(()=>{
        this.setState({
          'fetchError':true,
          'isLoading': false,
        });
      });
    } else {
      this.setState({
        suggestions: this.getSuggestions(value)
      });
    }
  }
  
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: []
    });
  }

  getSuggestions(value) {

    if(value == '' && this.props.showOnFocus){
      return this.props.options;
    }
   
    const escapedValue = this.escapeRegexCharacters(value.trim());
      
    if (escapedValue === '') {
      return [];
    }
      
    const regex = new RegExp('^' + escapedValue, 'i');
      
    return this.props.options.filter(option => regex.test(option.name));
    
  }

  // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  
  getSuggestionValue(suggestion) {
    this.props.form.setFieldValue(this.props.field.name, suggestion.value); 
    this.props.onChange && this.props.onChange(suggestion);
    return suggestion.name;
  }
  
  renderSuggestion(suggestion) {
    return (
      <span>{suggestion.name } { suggestion.data && suggestion.data.zipCode && <span className="react-autosuggest__additional-info">({suggestion.data.zipCode} {suggestion.data.city})</span> }</span>
    );
  }
 
  shouldRenderSuggestions() {
    return true;
  }

  onBlur(){
    if(this.state.suggestions.length === 1){
      this.setState({value: this.state.suggestions[0].name });
      this.props.form.setFieldValue(this.props.field.name, this.state.suggestions[0].value); 
      this.props.onChange && this.props.onChange(this.state.suggestions[0]);
    }
  }

  onFocus(){
    this.props.onFocus();
  }
  
  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: this.props.placeholder,
      value: value,
      onChange: this.onChange,
      onFocus: this.onFocus,
      onKeyDown: this.onKeyDown,
      onBlur: this.onBlur
    };
 
    return (
      <div className="react-select">
        {this.state.isLoading &&  
         <Spinner></Spinner>
        }
        <Autosuggest 
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps} 
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          highlightFirstSuggestion={true} />
       
        { this.state.fetchError && <div className="validation-msg">Beklager det har oppstått en teknisk feil. Prøv igjen senere.</div> }
        { this.state.noResults && <div className="validation-msg"> Ingen resultater ble funnet. Du kan fortsatt fylle ut dette feltet manuelt.</div> }
      </div>
    );
  } 
}
  
  
  