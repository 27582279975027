import React from 'react';
import PropTypes from 'prop-types';
import Header from 'SharedFrontend/src/Components/Header/Header';
function FormSection({
  title,
  id,
  className,
  children,
  headerSize,
  useEpiHeaderTag,
}) {
  return (
    <div id={id} className={className}>
      <Header headerSize={headerSize} useEpiHeaderTag={useEpiHeaderTag}>
        {title}
      </Header>
      <div className="form-section">{children}</div>
    </div>
  );
}

FormSection.propTypes = {
  headerSize: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
  useEpiHeaderTag: PropTypes.bool,
};

FormSection.defaultProps = {
  headerSize: 2,
  useEpiHeaderTag: false,
};

export default FormSection;
