/**
 * When rendering server side we don't have window available, so we use this helper
 * to make sure it is.
 */
export const windowAvailable = typeof window !== 'undefined';

export const documentAvailable = typeof document !== 'undefined';

export function isServerSide() {
    return !windowAvailable || !documentAvailable;
}

/**
 * This is set in ScriptConfig.cshtml
 */
export function isEpi() {
    if (typeof window === 'undefined' || !window.udfSettings) {
        return false;
    }
    return window.udfSettings.isEditMode;
}

export default {
    windowAvailable,
    documentAvailable,
    isServerSide,
    isEpi,
};
