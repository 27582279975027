import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactModal from 'react-modal';

import BodyLock from '../../../Utils/bodylock';

/**
 * To make sure our modals are accessible for screen readers etc.
 * we're using the react-modal package, that takes care of
 * setting the main wrapper tag to aria-hidden, and rendering
 * the modal outside of that.
 * Requires react-modal and setting up
 * e.g ReactModal.setAppElement('#main-wrapper');
 */
const FullScreenModal = ({
    children,
    active,
    setActive,
    className,
    closeButton,
}) => {
    useEffect(() => {
        if (active) {
            BodyLock.lockBodyScroll();
        } else {
            BodyLock.unlockBodyScroll();
        }
        return () => {
            BodyLock.unlockBodyScroll();
        };
    }, [active]);

    return (
        <ReactModal
            className={classNames('modal modal__main', className)}
            overlayClassName="modal modal__overlay"
            onRequestClose={() => {
                if (typeof setActive === 'function') {
                    setActive(false);
                }
            }}
            isOpen={active}
        >
            {closeButton}
            <div className="modal__inner-wrapper">
                <div className="modal__content-wrapper">{children}</div>
            </div>
        </ReactModal>
    );
};

FullScreenModal.propTypes = {
    closeButton: PropTypes.node,
    active: PropTypes.bool.isRequired,
    setActive: PropTypes.func.isRequired,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

FullScreenModal.defaultProps = {
    closeButton: null,
};

export default FullScreenModal;
