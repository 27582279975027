import React from 'react';
import PropTypes from 'prop-types';

const Cross = ({ className, active }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 384 383"
        aria-hidden="true"
    >
        <g fillRule="evenodd">
            <path
                style={{
                    opacity: active ? 0 : 1,
                    transition: 'ease-in-out opacity 0.1s',
                }}
                d="M167 0h50v383h-50z"
            />
            <path d="M383 167v50L0 216v-50z" />
        </g>
    </svg>
);

Cross.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool.isRequired,
};

Cross.defaultProps = {
    className: '',
};

export default Cross;
