import SearchBlock from './searchBlock';
import RegistrationPage from './forms/Registration/registrationPage';
import InformationLink from './UI/InformationLink/InformationLink';
import AccordionBlock from './AccordionBlock/AccordionBlock';
import MainNavigation from './Layout/MainNavigation/MainNavigation';
import CalendarEvent from './Layout/Event/CalendarEvent';
import TableBlock from './Blocks/TableBlock/TableBlock';
import LocalNavigation from './Layout/LocalNavigation/LocalNavigation';

global.PedStud = {
    MainNavigation,
    SearchBlock,
    RegistrationPage,
    InformationLink,
    AccordionBlock,
    TableBlock,
    CalendarEvent,
    LocalNavigation,
};
