import React from 'react'
import PropTypes from 'prop-types'

function RegistrationWrapper({children, className}) {
  return (
    <div className={`registration-container  ${className || ''}`}>
      {children}
    </div>
  )
}

RegistrationWrapper.propTypes = {

}

export default RegistrationWrapper

