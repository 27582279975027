import dayjs from 'dayjs';
import * as Yup from 'yup';
import {
    messages,
    regex,
    testPersonalNumber,
    testPositionPercent,
} from '../validation';

export function sendAnalytics(category, label) {
    console.warn('Sending Google Analytics has been temporarily disabled.');
    // FIXME: (03.2021) Temporarily removed as it was causing issues with the form
    // window.dataLayer.push({
    //   event: 'GAevent',
    //   eventCategory: 'Registration form',
    //   eventAction: category,
    //   eventLabel: label,
    // });
}

// TODO: (03.2021) Clean up/simplify this mess of a function
export function pushValidationErrors(errors) {
    for (const x in errors) {
        for (const y in errors[x]) {
            if (typeof errors[x][y] === 'object') {
                for (const z in errors[x][y]) {
                    sendAnalytics('Errors', `${x} ${y} ${z}`);
                }
            } else {
                sendAnalytics('Errors', y);
            }
        }
    }
}

export const preventSubmitOnEnter = event => {
    if (event.which === 13) {
        event.preventDefault();
    }
};

export const reservationsLabels = {
    email: 'E-post',
    sms: 'SMS',
    adsSoMe: 'Målrettet informasjon i sosiale medier',
};

const stringNumberRegex = /^\d+$/;

export const validationSchema = Yup.object().shape({
    recruiterPhone: Yup.string().phoneNumber(),
    membership: Yup.object().shape({
        schoolId: Yup.string().required(messages.required),
        studentEducationId: Yup.string().required(messages.required),
        graduationSemester: Yup.mixed()
            .test({ test: val => val, message: messages.required })
            .when('graduationYear', {
                is: val =>
                    parseInt(val, 10) ===
                        parseInt(dayjs().format('YYYY'), 10) &&
                    parseInt(dayjs().format('MM'), 10) > 6,
                then: Yup.mixed().test({
                    test: val => val !== 'spring',
                    message: messages.registrationDate,
                }),
            }),
        graduationYear: Yup.number().min(
            dayjs().format('YYYY'),
            messages.registrationDate
        ),
    }),
    personalia: Yup.object().shape({
        firstname: Yup.string().required(messages.required).nullable(),
        lastname: Yup.string().required(messages.required).nullable(),
        street: Yup.string().required(messages.required).nullable(),
        streetNumber: Yup.string()
            .matches(stringNumberRegex, messages.number)
            .nullable()
            .required(messages.required),
        zipCode: Yup.string()
            .required(messages.required)
            .matches(regex.zipcode, {
                message: messages.zipcode,
                excludeEmptyString: true,
            })
            .nullable(),
        mobile: Yup.string()
            .phoneNumber()
            .required(messages.required)
            .nullable(),
        city: Yup.string().required('Fyll inn gyldig postnummer').nullable(),
        ssn: Yup.mixed()
            .test({
                name: 'isValidFodselsnummer',
                message: messages.ssn,
                test: testPersonalNumber,
            })
            .nullable(),
        email: Yup.string()
            .required(messages.required)
            .matches(regex.email, {
                message: messages.email,
                excludeEmptyString: true,
            })
            .nullable(),
        streetEntrance: Yup.string().max(4, messages.maxLength(4)).nullable(),
    }),
});

export const getInitialValues = () => ({
    recruiterPhone: '',
    membership: {
        applicationDate: dayjs().format('DD/MM/YYYY'),
        schoolId: '',
        studentEducationId: '',
        school: '',
        studentEducation: '',
        graduationSemester: 'spring',
        graduationYear: dayjs().year() + 3,
        isWorking: '',
        membershipType: 'student',
    },
    personalia: {
        addressAbroad: '',
        city: '',
        countryId: '',
        email: '',
        firstname: '',
        lastname: '',
        mobile: '',
        postbox: '',
        ssn: '',
        streetEntrance: '',
        streetNumber: '',
        zipCode: '',
        hasAddressAbroad: '',
        hasCoAddress: '',
        hasPostbox: '',
    },
    reservations: {
        email: false,
        sms: false,
        adsSoMe: false,
    },
    // make sure that editing from summary won't loose data from more than  1 workplace /
});

export const postSignupValidationSchema = Yup.object().shape({
    workplaces: Yup.array().when('isWorking', {
        is: val => val,
        then: Yup.array().of(
            Yup.object().shape({
                city: Yup.string().required(messages.required),
                position: Yup.string().required(messages.required),
                positionPercent: Yup.string()
                    .test({
                        message: messages.positionPercent,
                        test: testPositionPercent,
                    })
                    .required(messages.required),
                workplaceName: Yup.string().required(messages.required),
            })
        ),
    }),
});

export const subscriptionTypes = {
    none: { id: 'none', label: 'Verken digitalt eller i posten' },
    digital: { id: 'digital', label: 'Digitalt' },
    mail: { id: 'mail', label: 'I posten' },
    both: { id: 'both', label: 'Både digitalt og i posten' },
};

export const postSignupInitialValues = {
    isWorking: '',
    workplaces: [],
    subscriptionMain: subscriptionTypes.digital.id,
    subscriptionExtra: subscriptionTypes.digital.id,
    studentInsurance: '',
    extraMagazine: '',
};

export const signupDefaultStrings = {
    recruit: {
        title: '',
        inputLabel: 'Ververs telefonnummer',
    },
};

export default {
    sendAnalytics,
    reservationsLabels,
    validationSchema,
    getInitialValues,
};
