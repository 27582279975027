import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const InputWrapper = ({ children, className, readOnly }) => (
    <div
        className={classNames(
            'input__wrapper',
            { disabled: readOnly },
            className
        )}
    >
        {children}
    </div>
);

InputWrapper.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    readOnly: PropTypes.bool,
};

InputWrapper.defaultProps = {
    children: null,
    className: null,
    readOnly: false,
};

export default InputWrapper;
