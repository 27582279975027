import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'SharedFrontend/src/Components/Accordion';
import InformationLink from '../UI/InformationLink/InformationLink';

const AccordionBlock = ({
  title,
  body,
  headingsize,
  useEpiHeaderTag,
  isopen,
  withpadding,
  linktitle,
  link,
}) => {
  return (
    <Accordion
      className="pedagog--accordion"
      title={title}
      headerTag={headingsize}
      useEpiHeaderTag={useEpiHeaderTag}
      withIcon
      isOpen={isopen}
      withPadding={withpadding}
    >
      <div dangerouslySetInnerHTML={{ __html: body }} />
      {link && <InformationLink link={link} title={linktitle} />}
    </Accordion>
  );
};

AccordionBlock.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  headingsize: PropTypes.string.isRequired,
  isopen: PropTypes.bool.isRequired,
  withpadding: PropTypes.bool,
  linktitle: PropTypes.string,
  link: PropTypes.string,
  useEpiHeaderTag: PropTypes.string,
};

AccordionBlock.defaultProps = {
  linktitle: '',
  link: '',
  withpadding: false,
  useEpiHeaderTag: true,
};

export default AccordionBlock;
