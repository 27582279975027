export default class BodyLock {
    /**
     * Simple vanillaJS way of locking the body from scrolling
     * For example when using a modal
     * mostly important for iOS devices
     */
    static bodyLockScrollPosition = 0;
    bodyLockEventName = 'bodyLock';
    static lockBodyScroll = () => {
        document.documentElement.classList.add('scroll-locked');
        document.dispatchEvent(
            new CustomEvent(this.bodyLockEventName, {
                detail: { locked: true },
            })
        );
    };

    static unlockBodyScroll = () => {
        document.documentElement.classList.remove('scroll-locked');
        document.dispatchEvent(
            new CustomEvent(this.bodyLockEventName, {
                detail: { locked: false },
            })
        );
    };

    static subscribe = callback => {
        document.addEventListener(this.bodyLockEventName, callback);
    };

    static unSubscribe = callback => {
        document.removeEventListener(this.bodyLockEventName, callback);
    };
}
