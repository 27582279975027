import React from 'react';

export default function Spinner(props) {
  let className = props.className ? props.className : '';
  return (
    <div className={'spinner '+ className}>
      <div className="double-bounce1"></div>
      <div className="double-bounce2"></div>
    </div>
  );
}