import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, useFormikContext } from 'formik';

import { scrollToFirstError } from '../../../../Utils/domUtils';

/**
 * Every Formik form uses a <Form> component, this replaces that and adds
 * functionality where the page will automatically scroll to the first error on the page
 * when the user tries to submit with errors.
 * It requires access to formik context.
 * https://github.com/formium/formik/issues/1484
 */
const FormikExpandedForm = React.forwardRef((props, forwardedRef) => {
    const { onSubmitError, ...rest } = props;

    const { submitCount, isValid } = useFormikContext();
    const [lastHandled, setLastHandled] = useState(0);

    useLayoutEffect(() => {
        if (submitCount > lastHandled && !isValid) {
            if (typeof onSubmitError === 'function') {
                onSubmitError();
            }
            setLastHandled(submitCount);
            scrollToFirstError();
        }
    }, [submitCount, isValid, lastHandled, onSubmitError]);

    /**
     * We add noValidate to the form, so the browser doesn't hijack our own
     * validation
     */
    return <Form ref={forwardedRef} noValidate {...rest} />;
});

FormikExpandedForm.propTypes = {
    onSubmitError: PropTypes.func,
};

FormikExpandedForm.defaultProps = {
    onSubmitError: null,
};

export default FormikExpandedForm;
