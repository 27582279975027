import { generalUtils } from 'SharedFrontend/src/Utils';
import { useState } from 'react';
import AdminDebugSelector from './adminDebugSelector';
import RegistrationComplete from './registrationComplete';
import RegistrationWrapper from './registrationContainer';
import RegistrationForm from './registrationForm';
import RegistrationSummary from './registrationSummary';
import RegistrationUpdateReceipt from './registrationUpdateReceipt';

function RegistrationPage({
  introText,
  postFormText,
  options,
  formSections,
  adminDebug,
  antiForgeryToken,
}) {
  console.log(
    'If this prints, the RegistrationPage component is being rendered succesfully.'
  );
  const [currentStep, setCurrentStep] = useState(1);
  const [registrationUpdateResults, setRegistrationUpdateResults] =
    useState(null);
  const [formValues, setFormValues] = useState(null);
  const [webId, setWebId] = useState(0);
  const [formSelectedSection, setFormSelectedSection] = useState('');

  const showSummary = (values) => {
    setCurrentStep(2);
    setFormValues(values);
    document.querySelector('.registration-form').scrollIntoView();
  };

  const editForm = (category) => {
    setFormSelectedSection(category);
    setCurrentStep(1);
  };

  const setIsComplete = (isComplete, webId) => {
    setCurrentStep(3);
    setWebId(webId);
  };

  const onRegistrationUpdateComplete = (results) => {
    setRegistrationUpdateResults(results);
    setCurrentStep(4);
  };

  return (
    <div className="registration-form">
      <AdminDebugSelector
        adminDebug={adminDebug}
        setCurrentStep={setCurrentStep}
      />
      <h1>Innmelding</h1>
      {introText && currentStep === 1 && (
        <RegistrationWrapper className="registration-intro">
          <div
            className="editorial-content"
            dangerouslySetInnerHTML={generalUtils.createMarkup(introText)}
          />
        </RegistrationWrapper>
      )}
      <div className="form-container">
        {currentStep === 1 && (
          <>
            <RegistrationForm
              options={options}
              values={formValues}
              showSummary={showSummary}
              selectedSection={formSelectedSection}
              formSections={formSections}
            />
          </>
        )}
        {currentStep === 2 && (
          <RegistrationSummary
            values={formValues}
            editForm={editForm}
            recruitmentSection={formSections.recruitmentSection}
            setIsComplete={setIsComplete}
            antiForgeryToken={antiForgeryToken}
          />
        )}
        {currentStep === 3 && (
          <RegistrationComplete
            ssn={formValues.personalia.ssn}
            webId={webId}
            formSections={formSections}
            onComplete={onRegistrationUpdateComplete}
            antiForgeryToken={antiForgeryToken}
          />
        )}
        {currentStep === 4 && (
          <RegistrationUpdateReceipt
            results={registrationUpdateResults}
            successSectionData={formSections.postRegistrationReceiptSection}
            errorSectionData={formSections.postRegistrationErrorSection}
          />
        )}
      </div>
      {postFormText && currentStep === 1 && (
        <RegistrationWrapper className="registration-intro">
          <div
            className="editorial-content"
            dangerouslySetInnerHTML={generalUtils.createMarkup(postFormText)}
          />
        </RegistrationWrapper>
      )}
    </div>
  );
}

export default RegistrationPage;
