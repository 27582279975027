import React from 'react';
import PropTypes from 'prop-types';
import AddToCalendar from 'SharedFrontend/src/Components/UI/AddToCalendar/AddToCalendar';
import { AddToCalendarData } from 'SharedFrontend/src/Models';
import { dateTimeUtils } from 'SharedFrontend/src/Utils';
const { formatForDisplay, dateFormats } = dateTimeUtils;

function CalendarEvent({
    pagetitle,
    facebookLink,
    eventStartDate,
    eventEndDate,
    addressPlace,
    price,
    deadline,
    travelDeadline,
    eventSignupLink,
    custom1Label,
    custom1Value,
    custom2Label,
    custom2Value,
}) {
    const eventItem = (label, text) => {
        if (!label && !text) {
            return null;
        }
        return (
            <li>
                <span>{`${label}:`}</span>
                {text}
            </li>
        );
    };

    const dateEventItem = (
        label,
        dateTime,
        format = dateFormats.readableDateTime
    ) => {
        if (dateTimeUtils.isMinValueOrNull(dateTime)) {
            return null;
        }
        return eventItem(label, formatForDisplay(dateTime, format));
    };

    const addToCalendarData = new AddToCalendarData(
        eventStartDate,
        eventEndDate,
        pagetitle,
        addressPlace
    );

    return (
        <div className="course-details calendar-event">
            <div className="course-details--buttons">
                <AddToCalendar
                    calendarData={addToCalendarData}
                    buttonElement={<button type="button">Test</button>}
                />
                {facebookLink && (
                    <div className="course-details--buttons__facebook">
                        <a
                            href={facebookLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="fb-link"
                        >
                            <span className="sr-only">
                                Arrangement på Facebook
                            </span>
                        </a>
                    </div>
                )}
            </div>
            <ul className="course-details__list">
                {dateEventItem(
                    'Start',
                    eventStartDate,
                    dateFormats.readableDateTime
                )}
                {!dateTimeUtils.isMinValueOrNull(eventEndDate) &&
                    dateEventItem(
                        'Slutt',
                        eventEndDate,
                        dateFormats.readableDateTime
                    )}
                {eventItem('Sted', addressPlace)}
                {eventItem('Pris', price)}
                {dateEventItem('Påmeldingsfrist', deadline, dateFormats.basic)}
                {eventItem(custom1Label, custom1Value)}
                {dateEventItem('Frist for reisebestilling', travelDeadline)}
                {eventItem(custom2Label, custom2Value)}
            </ul>

            {eventSignupLink && (
                <a
                    href={eventSignupLink}
                    target="_blank"
                    rel="noreferrer"
                    className="button signup__button"
                >
                    Meld meg på
                </a>
            )}
        </div>
    );
}

CalendarEvent.propTypes = {};

export default CalendarEvent;
