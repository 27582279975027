import React, { useState } from 'react';
import useEscapeKey from '../../Hooks/useEscapeKey';
import useHoverFocus from '../../Hooks/useHoverFocus';

/**
 * A tooltip that activates either on hover or on focus, as well as allowing to dismiss by pressing
 * the escape key
 * The dismissable part is important in order to fulfill content on hover or focus success criterion of WCAG
 * https://www.w3.org/WAI/WCAG21/Understanding/content-on-hover-or-focus.html
 * @param {ref} parentRef a ref to the element we want to attach the listeners to
 */
export default function Tooltip({ children, parentRef, className }) {
    const [active, setActive] = useState(false);
    const [dismissed, setDismissed] = useState(false);
    const onActivate = () => {
        setActive(true);
        setDismissed(false);
    };
    const onDeactivate = () => {
        setActive(false);
    };
    useEscapeKey(() => setDismissed(true));
    useHoverFocus(parentRef, onActivate, onDeactivate, true);
    const activeClass = active && !dismissed ? 'active' : '';
    return (
        <span className={`tooltip ${className || ''} ${activeClass}`}>
            <span className="tooltip-inner">{children}</span>
        </span>
    );
}
