import { shape, string, bool, number } from 'prop-types';

export const linkType = {
    type: shape({
        hasLink: bool,
        linkUrl: string.isRequired,
        linkText: string.isRequired,
        notifications: number,
    }),
    default: null,
};

export default { linkType };
