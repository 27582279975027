export const headerTags = {
    0: 'span',
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
};
/**
 * In episerver (HeaderSize.cs) the headers have been set up
 * with h2 starting at 0, so whenever we get a headersize enum from
 * epi we need to use this object instead.
 */
export const epiHeaderTags = {
    0: 'h2',
    1: 'h3',
    2: 'h4',
    3: 'h5',
    4: 'h6',
};

export const getHeaderTag = (useEpiHeaderTag, tagKey) => {
    const headerTag = useEpiHeaderTag
        ? epiHeaderTags[tagKey]
        : headerTags[tagKey];
    // If the key doesn't match any of the defined enums, we just return the default span
    return headerTag || headerTags[0];
};

export default { headerTags, getHeaderTag, epiHeaderTags };
