var headerOfset = 0;

export function setupHeaderOfset(newHeaderOfset) {
    headerOfset = newHeaderOfset;
}

export function scrollTo(y, smooth = true) {
    const isSmoothScrollSupported =
        'scrollBehavior' in document.documentElement.style;
    if (smooth && isSmoothScrollSupported) {
        // not on IE/Edge
        window.scrollTo({ top: y, behavior: 'smooth' });
    } else {
        // for Edge
        document.body.scrollTop = y;
        document.documentElement.scrollTop = y;
    }
}

export function scrollToElement(domElement, offset = headerOfset) {
    // const elementPosition = domElement.getBoundingClientRect().top;
    // const offsetPosition = window.pageYOffset + elementPosition;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = domElement.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    scrollTo(offsetPosition);
}

export function scrollToTopOfForm(formId = null) {
    let formElement;
    if (formId) {
        formElement = document.getElementById(formId);
    } else {
        formElement = document.getElementsByClassName('form-wrapper')[0];
    }
    // Every form should be wrapped in "form-wrapper", but if there is none we default to just the first form we find
    if (!formElement) {
        formElement = document.getElementsByTagName('form')[0];
    }
    scrollToElement(formElement);
}

export function scrollToFirstError() {
    const element = document.getElementsByClassName('has-error')[0];
    const label = element ? element.closest('label') : null;
    const input = label ? label.getElementsByTagName('input')[0] : null;
    if (label || element) {
        if (input) {
            input.focus({ preventScroll: true });
        }
        scrollToElement(label || element);
    }
}

export default {
    setupHeaderOfset,
    scrollTo,
    scrollToElement,
    scrollToTopOfForm,
    scrollToFirstError,
};
