import React from 'react';
import PropTypes from 'prop-types';

import EpiLink from 'UI/Links/EpiLink';
import HeaderSearch from './HeaderSearch';
import MegaMenu from './MegaMenu';

const MainNavigation = ({
    loginLink,
    logoutLink,
    profileLink,
    membershipLink,
    searchAction,
    megamenuItems,
    personalMenuItems,
}) => (
    <nav className="main-navigation main-container">
        {membershipLink && (
            <EpiLink className="membership-link" linkModel={membershipLink} />
        )}

        {loginLink && <EpiLink className="login-link" linkModel={loginLink} />}

        {profileLink && (
            <EpiLink className="profile-link" linkModel={profileLink} />
        )}

        <HeaderSearch searchAction={searchAction} />

        <MegaMenu
            menuItems={megamenuItems}
            loginLink={loginLink}
            logoutLink={logoutLink}
            membershipLink={membershipLink}
            profileLink={profileLink}
            personalMenuItems={personalMenuItems}
        />
    </nav>
);

MainNavigation.propTypes = {
    loginLink: PropTypes.object,
    logoutLink: PropTypes.object,
    profileLink: PropTypes.object,
    membershipLink: PropTypes.object,
    searchAction: PropTypes.string,
    megamenuItems: PropTypes.array,
    personalMenuItems: PropTypes.array,
};

export default MainNavigation;
