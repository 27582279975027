import React, { Component } from 'react';
import dayjs from 'dayjs';
import { Radio } from 'SharedFrontend/src/Components/Forms/Input';
import { sendAnalytics } from './Registration/registrationFormHelpers';

export default class semesterPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSemester: this.props.value.semester || 'spring',
      selectedYear: this.props.value.year || dayjs().year() + 3,
      isOpen: false,
    };

    this.getNextYear = this.getNextYear.bind(this);
    this.getPreviousYear = this.getPreviousYear.bind(this);
    this.openTooltip = this.openTooltip.bind(this);
    this.selectSemester = this.selectSemester.bind(this);
    this.setYear = this.setYear.bind(this);
    this.showValidationError = this.showValidationError.bind(this);
  }

  getNextYear() {
    this.setState({ selectedYear: parseInt(this.state.selectedYear) + 1 });
    this.props.form.setFieldValue(
      'membership.graduationYear',
      parseInt(this.state.selectedYear) + 1
    );
  }

  getPreviousYear() {
    this.setState({ selectedYear: parseInt(this.state.selectedYear) - 1 });
    this.props.form.setFieldValue(
      'membership.graduationYear',
      parseInt(this.state.selectedYear) - 1
    );
  }

  setYear(e) {
    if (isNaN(parseInt(e.target.value))) {
      return;
    }
    this.setState({ selectedYear: parseInt(e.target.value) });
    this.props.form.setFieldValue(
      'membership.graduationYear',
      parseInt(e.target.value)
    );
  }

  selectSemester(semester) {
    this.setState({ selectedSemester: semester });
    this.props.form.setFieldValue('membership.graduationSemester', semester);
  }

  openTooltip() {
    this.setState({ isOpen: true });
    let input = document.querySelector('#springSemester + label');
    setTimeout(() => {
      input.focus();
    }, 0);
  }

  showValidationError(category, field) {
    let { errors, touched } = this.props.form;
    return (
      errors[category] &&
      errors[category][field] &&
      ((touched[category] && touched[category][field]) ||
        this.props.isSent) && (
        <div className="validation-msg">{errors[category][field]}</div>
      )
    );
  }

  radioBlur(event) {
    //event.relatedTarget
  }

  render() {
    return (
      <fieldset>
        <legend>Planlagt fullført (vår eller høst/åååå)</legend>
        <div className="semester-picker__field">
          <div className="semester-picker__term">
            <fieldset>
              <legend>
                Vår eller høst <span className="mandatory">*</span>
              </legend>
              <div className="semester-picker__radio">
                <Radio
                  label="Våren"
                  id="springSemester"
                  onBlur={(event) => {
                    this.radioBlur(event);
                  }}
                  onChange={() => {
                    this.selectSemester('spring');
                  }}
                  name="semesterPicker"
                  defaultChecked={this.state.selectedSemester === 'spring'}
                  onFocus={() => {
                    sendAnalytics('Field focus', 'Vår eller høst');
                  }}
                />
              </div>
              <div className="semester-picker__radio">
                <Radio
                  label="Høsten"
                  id="fallSemester"
                  onChange={() => {
                    this.selectSemester('fall');
                  }}
                  onFocus={() => {
                    sendAnalytics('Field focus', 'Vår eller høst');
                  }}
                  name="semesterPicker"
                  defaultChecked={this.state.selectedSemester === 'fall'}
                />
              </div>
            </fieldset>
          </div>
          {this.showValidationError('membership', 'graduationSemester')}
        </div>
        <div className="semester-picker__field">
          <div className="semester-picker__year">
            <label htmlFor="term-input">
              Årstall <span className="mandatory">*</span>
            </label>
            <button
              type="button"
              className="semester-picker__previous-btn"
              onClick={this.getPreviousYear}
              onFocus={() => {
                sendAnalytics('Field focus', 'Årstall button');
              }}
            >
              <span>prev</span>
            </button>
            {
              <div className="semester-picker__value">
                {this.state.selectedYear}
              </div>
            }
            <button
              type="button"
              className="semester-picker__next-btn"
              onClick={this.getNextYear}
              onFocus={() => {
                sendAnalytics('Field focus', 'Årstall button');
              }}
            >
              <span>next</span>
            </button>
          </div>
          {this.showValidationError('membership', 'graduationYear')}
        </div>
      </fieldset>
    );
  }
}
