import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import useCallbackRef from '../../../../Hooks/useCallbackRef';
import Tooltip from '../../../Tooltip/Tooltip';

/**
 * Checkbox doesn't include error message, if you
 * need error messages you should probably use an InputGroup
 */
const Checkbox = ({
    name,
    label,
    checked,
    value,
    id,
    labelClassName,
    inputClassName,
    onChange,
    onClick,
    ariaLabelledBy,
    additionalText,
    toolTipText,
    disabled,
}) => {
    const [labelRef, labelElement] = useCallbackRef();

    return (
        <label
            className={classNames(
                'form-checkbox',
                {
                    'has-additional-text': additionalText,
                    disabled: disabled,
                    checked: checked,
                },
                labelClassName
            )}
            ref={toolTipText ? labelRef : null}
            htmlFor={id}
        >
            <input
                name={name}
                type="checkbox"
                checked={checked}
                className={classNames('form-checkbox__input', inputClassName)}
                value={value}
                id={id}
                onChange={onChange}
                onClick={onClick}
                aria-labelledby={ariaLabelledBy || null}
                disabled={disabled}
            />
            <span className="form-checkbox__box">
                <div className="icon" />
            </span>
            <span className="form-checkbox__label">
                {label}
                {additionalText && (
                    <span className="label__additional-text">
                        {additionalText}
                    </span>
                )}
                {toolTipText && (
                    <Tooltip parentRef={labelElement}>{toolTipText}</Tooltip>
                )}
            </span>
        </label>
    );
};

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    checked: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    ariaLabelledBy: PropTypes.string,
    additionalText: PropTypes.string,
    toolTipText: PropTypes.string,
    labelElement: PropTypes.shape({ current: PropTypes.node }),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

Checkbox.defaultProps = {
    label: '',
    labelClassName: '',
    inputClassName: '',
    ariaLabelledBy: '',
    additionalText: '',
    value: '',
    toolTipText: null,
    labelElement: null,
    disabled: false,
};

export default Checkbox;
