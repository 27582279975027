import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../forms/spinner';

function SubmitButton({ children, id, className, isSubmitting, onClick }) {
  return (
    <div className="submit-button-wrapper">
      <button
        type="submit"
        id={id}
        className={`button ${className || ''}`}
        disabled={isSubmitting}
        onClick={typeof onClick === 'function' ? onClick : null}
      >
        {children}
      </button>
      {isSubmitting && <Spinner className="spinner--summary" />}
    </div>
  );
}

SubmitButton.propTypes = {};

export default SubmitButton;
