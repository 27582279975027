import React from 'react';
import PropTypes from 'prop-types';

import { createMarkup } from '../../Utils/generalUtils';
import { getHeaderTag } from '../../Utils/enums';

const Header = ({
    children,
    rawContent,
    headerSize,
    className,
    useEpiHeaderTag,
    id,
}) => {
    if (!children && !rawContent) {
        return null;
    }

    const HeaderTag = getHeaderTag(useEpiHeaderTag, headerSize);
    if (rawContent) {
        return (
            <HeaderTag
                className={className}
                dangerouslySetInnerHTML={createMarkup(rawContent)}
            />
        );
    }

    return (
        <HeaderTag id={id} className={className}>
            {children}
        </HeaderTag>
    );
};

Header.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    headerSize: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
    useEpiHeaderTag: PropTypes.bool,
    id: PropTypes.string,
    rawContent: PropTypes.string,
};

Header.defaultProps = {
    className: '',
    children: null,
    headerSize: 0,
    useEpiHeaderTag: false,
    id: null,
};

export default Header;
