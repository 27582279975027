import { isNotProd } from './generalUtils';
import { getCookieValue } from './storageUtils';

const userTypeCookieName = 'UdfUserType';

export function dataLayerPush(dataObject) {
    if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataObject);
    }
}

export function hotjarTrigger(triggerName, variable) {
    if (isNotProd()) {
        return;
    }
    if (typeof window !== 'undefined' && window.hj) {
        /*
         *  HJ doesn't allow / in trigger, so we replace / with _.
         * If the triggerName is only / like on the home page the trigger will
         * have double underscore followed by the variable (__variable)
         */
        window.hj('trigger', `${triggerName.replace('/', '_')}_${variable}`);
    }
}

export function pushUserType() {
    const userType = getCookieValue(userTypeCookieName) || 'anonymous';
    if (userType) {
        dataLayerPush({
            userType,
        });
        hotjarTrigger(window.location.pathname, userType);
    }
}

export function CollapseExpand(label, category) {
    dataLayerPush({
        event: 'Åpnet/Lukket Element',
        category,
        label,
    });
}
export function CollapseExpandOpened(label) {
    CollapseExpand(label, 'Ekspandert Element');
}
export function CollapseExpandClosed(label) {
    CollapseExpand(label, 'Lukket Element');
}

/**
 * A data-layer that is pushed when a link is clicked will always need to
 * pass a callback that redirects to the link clicked. We add a 1 second timeout
 * just in case something happens with the GTM code.
 */
export const linkClickedPush = (dataObject, href) => {
    const dataWithCallback = {
        ...dataObject,
        eventCallback() {
            window.location = href;
        },
        eventTimeout: 1000,
    };
    dataLayerPush(dataWithCallback);
};

export default {
    hotjarTrigger,
    dataLayerPush,
    linkClickedPush,
    pushUserType,
};
