const popovers = [];
let popOverActive = false;

/**
 * The sites have over the years had some variations in the way popovers are made in TinyMCE
 * We have tried to edit as many as possible of them to be a similar structure, but there are still
 * some old variations left.
 * Old popovers will be using an <a> tag, while new ones will be using a <button> tag
 */
class PopOver {
    button;
    content;
    wrapper;
    arrow = undefined;
    constructor(popoverButton, contentId) {
        this.button = popoverButton;
        const popoverContent = PopOver.getContent(popoverButton, contentId);
        if (popoverContent == null) {
            popoverButton.classList.add('popover-error');
            popoverButton.setAttribute('aria-disabled', true);
            return;
        }
        this.content = popoverContent;
        this.wrapper = popoverContent.parentNode;
        if (this.wrapper.nodeName === 'TD') {
            this.wrapper = this.wrapper.closest('table');
        }
        if (popoverButton.tagName === 'A') {
            this.setupLinkAsButton();
        }
        popoverContent.onclick = e => {
            e.stopImmediatePropagation();
        };
        // eslint-disable-next-line no-param-reassign
        popoverButton.onclick = this.onClick;
    }

    /**
     * The old footnoes will have an "arrow" div already,
     * but the bibliography notes will not, so we'll just make a new one
     * on the first click.
     * We need an actual arrow element in ordet to position it correctly.
     */
    addArrow() {
        const arrowElement = document.createElement('span');
        arrowElement.classList.add('popover-arrow');
        this.arrow = arrowElement;
        this.content.appendChild(arrowElement);
    }

    /**
     * Old footnotes in tinymce are set up with empty links, which
     * is not accessible at all, so we have to hook them up with correct
     * functionality
     */
    setupLinkAsButton = () => {
        const { button } = this;
        button.setAttribute('tabindex', '0');
        button.setAttribute('role', 'button');
        button.onkeydown = e => {
            if (e.keyCode === 32 || e.keyCode === 13) {
                e.preventDefault();
                button.click();
            }
        };
    };

    onClick = event => {
        event.stopImmediatePropagation();
        if (this.arrow == null) {
            /**
             * We add the arrow on the first click, to avoid adding
             * hundreds of elements on page load in some articles
             */
            this.addArrow();
        }
        this.toggleActive();
        this.setPosition();
        if (!popOverActive) {
            popOverActive = true;
        }
    };

    setPosition() {
        const { button, arrow, wrapper, content } = this;
        const topPosition = button.offsetTop + button.offsetHeight;
        let leftPosition = button.offsetLeft;
        const wrapperWidth = wrapper.offsetWidth;
        const rightEdge = leftPosition + content.offsetWidth;
        if (rightEdge > wrapperWidth) {
            leftPosition -= rightEdge - wrapperWidth;
        }
        arrow.setAttribute(
            'style',
            `left: ${button.offsetLeft - leftPosition}px`
        );
        content.setAttribute(
            'style',
            `top: ${topPosition}px; left: ${leftPosition}px;`
        );
    }

    toggleActive() {
        this.content.classList.toggle('active');
    }

    removeActive() {
        this.content.classList.remove('active');
    }

    static getContent(button, contentId) {
        const originalContent = document.getElementById(contentId);
        if (originalContent == null) {
            return null;
        }
        let content = originalContent;
        // Bibliography is visible at the bottom of the document, so we need to clone it and reuse that element in the popover
        if (content.classList.contains('bibliography')) {
            content = this.getClonedContent(button, originalContent);
        }
        return content;
    }

    static getClonedContent(button, originalContent) {
        const clonedContent = originalContent.cloneNode(true);
        // Remove the ID so we don't get duplicates
        clonedContent.removeAttribute('id');
        // We insert the cloned content in the same parent as the button, so we make sure they're position together
        const content = button.parentNode.appendChild(clonedContent);
        content.classList.add('clone');
        return content;
    }
}
function hidePopovers() {
    /** Since the list of content contains all popover content on the page
     * we only deactivate them if we know there's at least one active
     */
    if (popOverActive) {
        popovers.forEach(popover => {
            if (popover.removeActive) {
                popover.removeActive();
            }
        });
        popOverActive = false;
    }
}
/**
 * Finds all popover buttons/links when the page loads and sets up the content to display on click
 */
function setupPopovers() {
    const popoverButtons = document.getElementsByClassName('popover-link');
    for (const popoverButton of popoverButtons) {
        const contentId = popoverButton.getAttribute('data-popover-id');
        if (contentId) {
            const popover = new PopOver(popoverButton, contentId);
            popovers.push(popover);
        }
    }
    document.addEventListener('click', hidePopovers);
    document.addEventListener('keydown', keyEvent => {
        if (keyEvent.key === 'Escape') {
            hidePopovers();
        }
    });
}

export default function Initialize() {
    if (typeof window !== 'undefined') {
        window.addEventListener('DOMContentLoaded', setupPopovers, false);
    }
}
