import dayjs from 'dayjs';

export const dateFormats = {
    default: 'dddd DD. MMM',
    basic: 'D.MM.YYYY',
    basicMonthLetters: 'D. MMM YYYY',
    time: 'HH:mm',
    epi: 'DD.MM.YYYY',
    month: 'MM/YYYY',
    readableDateTime: 'D.MM.YYYY, kl. HH:mm',
};

export function parseEpiDate(dateTime) {
    if (!dateTime) {
        return null;
    }
    const parsedDate = dayjs(dateTime, 'YYYY-MM-DD');
    if (dayjs().isValid(parsedDate)) {
        return parsedDate;
    }
    return dayjs(dateTime, dateFormats.epi);
}

export function formatForDisplay(
    dateString,
    format = dateFormats.default,
    isEpiDate = false
) {
    if (!dateString) {
        return null;
    }
    let validDate = dateString;
    if (isEpiDate) {
        validDate = parseEpiDate(dateString);
    }
    if (!dayjs().isValid(validDate)) {
        return dateString;
    }
    return dayjs(validDate).format(format);
}

export function calculateAge(birthday) {
    if (!birthday) {
        return 0;
    }
    let ageDifMs;
    if (dayjs.isDayjs(birthday)) {
        ageDifMs = dayjs().subtract(birthday, 'ms');
    } else if (birthday.getTime) {
        ageDifMs = Date.now() - birthday.getTime();
    } else {
        throw new Error(
            'calculateAge requires either a Date object or Dayjs object'
        );
    }
    const ageDate = new Date(ageDifMs); // milliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function isMinValueOrNull(dateTime) {
    if (!dateTime) {
        return true;
    }
    const parsedYear = dayjs(dateTime).format('YYYY');
    if (parsedYear === '0001' || parsedYear === '1901') {
        return true;
    }
    return false;
}

export function isNotMinValueOrNull(dateTime) {
    return !isMinValueOrNull(dateTime);
}

export function formatDateForEpi(dateTime) {
    if (!dateTime) {
        console.warn('Date is empty, formatting skipped');
        return null;
    }
    const validDateObject = dayjs(dateTime);
    if (!dayjs.isDayjs(validDateObject)) {
        throw new Error(`${dateTime} is not a valid date object`);
    }
    return validDateObject.format(dateFormats.epi);
}

export default {
    calculateAge,
    dateFormats,
    formatForDisplay,
    parseEpiDate,
    isMinValueOrNull,
    isNotMinValueOrNull,
    formatDateForEpi,
};
