import React from 'react';
import PropTypes, { bool } from 'prop-types';

import classNames from 'classnames';

import ErrorMessage from '../ErrorMessage/ErrorMessage';

const InputInfo = ({ error, info, className }) => (
    <div
        className={classNames(
            'input--info',
            { 'with-info': info, 'with-error': error },
            className
        )}
    >
        {info ? <span className="input--infofield">{info}</span> : null}
        <ErrorMessage errorMessage={error} />
    </div>
);

InputInfo.propTypes = {
    error: PropTypes.oneOfType([PropTypes.string, bool]),
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
};

InputInfo.defaultProps = {
    error: null,
    info: null,
};

export default InputInfo;
