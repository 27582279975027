import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Tooltip from 'react-tooltip-lite';
import { CheckboxFormik } from 'SharedFrontend/src/Components/Forms/Formik';
import { preventSubmitOnEnter, sendAnalytics } from './registrationFormHelpers';
import WorkplaceFields from './workplaceFields';

const defaultWorkplace = {
  address: '',
  city: '',
  newWorkplace: false,
  position: '',
  positionId: '',
  positionPercent: '',
  unionChapter: '',
  unionChapterId: '',
  workplaceId: '',
  workplaceName: '',
  workplaceType: '',
  workplaceTypeName: '',
  zipCode: '',
  id: 0,
};

const workplacesKey = 'workplaces';

function WorkplaceSection() {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [nextWorkplaceId, setNextWorkplaceId] = useState(0);

  useEffect(() => {
    const clickListener = document.body.addEventListener('click', () => {
      setIsTooltipOpen(false);
    });
    return () => {
      document.body.removeEventListener('click', clickListener);
    };
  }, []);

  const { values, setFieldValue } = useFormikContext();

  const { isWorking, workplaces } = values;

  const addWorkplace = () => {
    const newWorkplace = { ...defaultWorkplace, id: nextWorkplaceId };
    setNextWorkplaceId(nextWorkplaceId + 1);
    setFieldValue(workplacesKey, [...workplaces, newWorkplace]);
  };
  const removeWorkplace = (idToRemove) => {
    setFieldValue(
      workplacesKey,
      workplaces.filter((workplace) => workplace.id !== idToRemove)
    );
  };

  return (
    <div className="workplace-section">
      <div className="checkbox-tooltip-group checkbox-wrapper">
        <CheckboxFormik
          label="Ønsker du å betale for fulle arbeidstakerrettigheter?"
          name="isWorking"
          onChangeSideEffect={(value, checked) =>
            checked && workplaces.length === 0 && addWorkplace()
          }
        />
        <Tooltip
          direction="down-start"
          padding="0"
          tipContentHover
          isOpen={isTooltipOpen}
          arrow
          content={
            <div className="outdated-article-tooltip">
              <p>
                Vanlig studentmedlemskap med ivaretakelse av studentrettigheter
                er gratis. Arbeider du imidlertid i en fast stilling på 20 %
                eller mer i barnehage- og skolesektoren ved siden av studiene,
                kan du velge å betale ordinær kontingent (1,25 % av inntekten)
                for å få fulle rettigheter og plikter som arbeidstakermedlem i
                Utdanningsforbundet, også i løpet av studietiden. Dette er helt
                valgfritt.
              </p>
            </div>
          }
        >
          <button
            type="button"
            className="round-button--small"
            onClick={() => {
              setIsTooltipOpen((prev) => !prev.isTooltipOpen);
              sendAnalytics(
                'Button click',
                'Ønsker du å betale for fulle arbeidstakerrettigheter? tooltip'
              );
            }}
          >
            ?
          </button>
        </Tooltip>
      </div>
      {isWorking &&
        workplaces.map((workplace, i) => (
          <WorkplaceFields
            formKey={workplacesKey}
            key={workplace.id}
            workplaceIndex={i}
            preventSubmitOnEnter={preventSubmitOnEnter}
            addWorkplace={i < 3 && i === workplaces.length - 1 && addWorkplace}
            removeWorkplace={i > 0 && removeWorkplace}
          />
        ))}
    </div>
  );
}

WorkplaceSection.propTypes = {};

export default WorkplaceSection;
