export default class AddToCalendarData {
    arrangementStart;
    arrangementStop;
    title;
    intro;
    arrangementAddress;

    constructor(
        arrangementStart,
        arrangementStop,
        title,
        arrangementAddress,
        intro = null
    ) {
        this.arrangementStart = arrangementStart;
        this.arrangementStop = arrangementStop;
        this.intro = intro;
        this.title = title;
        this.arrangementAddress = arrangementAddress;
    }
}
