/* eslint-disable prefer-destructuring */
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import Accordion from './Accordion';
import { accordionToggle } from '../../Events/events';

/**
 * Episerver allows using blocks within TinyMCE editors. This makes it very difficult to
 * turn for example EditorialBlock into a React component, because it could contain a block
 * that is also rendered as a React component. We can't render a React component within a React component,
 * so since EditorialBlock is using relatively basic functionality, we render Accordion as static html,
 * put all the DOM content (including react components) inside, and then hook up the functionality manually.
 * This is *not* efficient at all, but the only "safe" way to do it.
 */
class DOMAccordion {
    wrapper;
    accordionElement;
    originalContentWrapper;
    contentWrapper;
    contentInnerWrapper;
    removeMaxHeight;
    /**
     * @param {DOM element} wrapper Existing wrapper to put the accordion inside of
     * @param {object} props "props", similar to react props, just a collection of settings
     * @example <caption>Example usage</caption>
     *  const accordionWrappers = document.getElementsByClassName(
     *    'dom-accordion-wrapper'
     *  );
     *  accordionWrappers.forEach(accordionWrapper => {
     *    const propAttribute = accordionWrapper.getAttribute('data-props');
     *    if (propAttribute) {
     *      const props = JSON.parse(propAttribute);
     *      const accordion = new DOMAccordion(accordionWrapper, props);
     *    }
     *  });
     */
    constructor(wrapper, props) {
        this.props = props;
        this.wrapper = wrapper;
        const { title, headerSize, startOpen = false } = props;
        this.originalContentWrapper =
            wrapper.getElementsByClassName('content')[0];
        const accordionMarkup = ReactDOMServer.renderToStaticMarkup(
            <Accordion
                title={title}
                headerSize={headerSize}
                useEpiHeaderTag
                withIcon
                isOpen={startOpen}
            />
        );
        this.wrapper.insertAdjacentHTML('beforeend', accordionMarkup);
        this.accordionElement = this.wrapper.lastChild;
        const newWrapper = this.accordionElement.getElementsByClassName(
            'accordion__contents-inner'
        )[0];
        newWrapper.append(this.originalContentWrapper);
        this.contentWrapper = wrapper.getElementsByClassName(
            'accordion__contents'
        )[0];
        this.contentInnerWrapper = this.contentWrapper.children[0];
        this.setupAccordionButton();
        this.eventSubscribe();
        if (startOpen) {
            this.setActivated();
        } else {
            this.setDeactivated();
        }
        this.wrapper.classList.add('initialized');
    }

    eventSubscribe() {
        accordionToggle.subscribe((msg, eventActivate) => {
            if (eventActivate) {
                this.activate();
            } else {
                this.deactivate();
            }
        });
    }

    setupAccordionButton() {
        const button =
            this.wrapper.getElementsByClassName('accordion__button')[0];
        button.onclick = () => this.toggleAccordion(this.props);
        this.button = button;
    }

    beforeShow = () => {
        this.contentWrapper.style.removeProperty('display');
    };

    removeMaxHeight = () => {
        this.contentWrapper.style.removeProperty('max-height');
    };

    onShowEnd = () => {
        this.removeMaxHeight();
    };

    onHideEnd = () => {
        this.contentWrapper.style.setProperty('display', 'none');
    };

    getContentHeight = () => {
        // There's an extra bit of spacing on the bottom that I can't find right now, throwing in this for now
        return this.contentInnerWrapper.scrollHeight + 50;
    };

    setDeactivated() {
        const { contentWrapper, onHideEnd } = this;
        contentWrapper.style.setProperty('max-height', '0px');
        contentWrapper.addEventListener('transitionend', onHideEnd);
        this.accordionElement.classList.remove('active');
        this.button.setAttribute('aria-expanded', 'false');
    }

    deactivate() {
        const { contentWrapper, onShowEnd } = this;
        contentWrapper.removeEventListener('transitionend', onShowEnd);
        const height = this.getContentHeight();
        contentWrapper.style.setProperty('max-height', `${height}px`);
        setTimeout(() => {
            this.setDeactivated();
        }, 20);
        this.active = false;
    }

    setActivated() {
        const { contentWrapper, onShowEnd } = this;
        const height = this.getContentHeight();
        contentWrapper.style.setProperty('max-height', `${height}px`);
        contentWrapper.addEventListener('transitionend', onShowEnd);
        this.accordionElement.classList.add('active');
        this.button.setAttribute('aria-expanded', 'true');
    }

    activate() {
        const { contentWrapper, onHideEnd } = this;
        contentWrapper.removeEventListener('transitionend', onHideEnd);
        contentWrapper.style.removeProperty('display');
        setTimeout(() => {
            this.setActivated();
        }, 20);
        this.active = true;
    }

    toggleAccordion() {
        if (this.active) {
            this.deactivate();
        } else {
            this.activate();
        }
    }
}

export default DOMAccordion;
