import React from 'react';
import PropTypes from 'prop-types';

import { linkClickedPush } from '../../../Utils/analyticsUtils';

const BaseLink = React.forwardRef(
    (
        {
            href,
            children,
            onClick,
            target,
            title,
            analyticsEventName,
            analyticsValue,
            className,
            dataGroup,
            labelText,
            tabIndex,
            visuallyHidden,
            rel,
            id,
        },
        ref
    ) => {
        const hasOnClick = typeof onClick === 'function';
        const hasAnalytics = analyticsEventName !== '' && analyticsValue !== '';
        const linkClicked = event => {
            if (hasOnClick) {
                onClick(event);
            }
            if (hasAnalytics) {
                event.preventDefault();
                linkClickedPush(
                    {
                        event: analyticsEventName,
                        type: analyticsValue,
                    },
                    href
                );
            }
        };

        return (
            <a
                id={id}
                aria-label={labelText}
                ref={ref}
                href={href}
                target={target}
                rel={rel}
                tabIndex={visuallyHidden ? -1 : tabIndex}
                // Used for tracking in GTM
                data-group={dataGroup}
                title={title}
                onClick={hasOnClick || hasAnalytics ? linkClicked : null}
                className={className}
            >
                {children}
            </a>
        );
    }
);

BaseLink.displayName = 'BaseLink';

BaseLink.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    labelText: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
    className: PropTypes.string,
    analyticsEventName: PropTypes.string,
    analyticsValue: PropTypes.string,
    dataGroup: PropTypes.string,
    // We really shouldn't be using tabIndex for anything but disabling tabIndex
    tabIndex: PropTypes.oneOf([-1, null]),
    visuallyHidden: PropTypes.bool,
    rel: PropTypes.string,
    id: PropTypes.string,
    onClick: PropTypes.func,
};

BaseLink.defaultProps = {
    target: null,
    analyticsEventName: '',
    title: '',
    analyticsValue: '',
    className: '',
    dataGroup: null,
    tabIndex: null,
    visuallyHidden: false,
};

export default BaseLink;
