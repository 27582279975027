import React, { useState, useCallback } from 'react';

/**
 * useRef doesn't notif you once the ref.current has been set, and
 * doesn't trigger a re-render, so instead we can use a useCallback to give us
 * that functionality
 * https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
 */
export default function useCallbackRef() {
    const [elementRef, setElementRef] = useState(null);
    const ref = useCallback(node => {
        if (node !== null) {
            setElementRef(node);
        }
    }, []);
    return [ref, elementRef];
}
