import React, { Component } from 'react';

export default class SearchBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFilter: null,
            isFilteringVisible: false,
        };
        this.state.options = this.props.options;
        this.selectFilter = this.selectFilter.bind(this);
        this.renderOptions = this.renderOptions.bind(this);
        this.renderfilterButtons = this.renderfilterButtons.bind(this);
        this.resetOptions = this.resetOptions.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.getSelectedFilterOptions =
            this.getSelectedFilterOptions.bind(this);
        this.updateOptionsForFilter = this.updateOptionsForFilter.bind(this);
        this.showFilters = this.showFilters.bind(this);
    }

    renderOptions(optionsData, groupName) {
        const { value, id, label, selected } = optionsData;
        return (
            <div key={id}>
                <input
                    id={id}
                    name={groupName}
                    onChange={() => {
                        this.selectOption(id);
                    }}
                    checked={selected}
                    className="hidden-checkbox"
                    type="checkbox"
                    value={value}
                />
                <label htmlFor={id} className="checkbox">
                    {label}
                </label>
            </div>
        );
    }

    getSelectedFilterOptions(selectedFilter) {
        return this.state.options.find((option) => {
            return option.optionname === selectedFilter;
        }).optionitems;
    }

    updateOptionsForFilter(filterName, updatedOptions) {
        let options = this.state.options.slice();

        return Object.values(options).map((option) => {
            if (option.optionname === filterName) {
                option.optionitems = updatedOptions;
            }
            return option;
        });
    }

    selectOption(selectOptionId) {
        let updatedOptions = this.getSelectedFilterOptions(
            this.state.selectedFilter
        ).map((option) => {
            if (option.id === selectOptionId) {
                option.selected = !option.selected;
            }
            return option;
        });

        this.setState({
            options: this.updateOptionsForFilter(
                this.state.selectedFilter,
                updatedOptions
            ),
        });
    }

    selectFilter(filterType) {
        if (filterType === this.state.selectedFilter) {
            this.setState({ selectedFilter: null });
        } else {
            this.setState({ selectedFilter: filterType });
        }
    }

    renderfilterButtons(filter) {
        const optionsNumber = filter.optionitems.length;
        let selectedOptions = filter.optionitems.filter((option) => {
            return option.selected && option.selected === true;
        }).length;
        return (
            <li key={filter.optionname}>
                <button
                    type="button"
                    className={
                        'filtering-block_button' +
                        (this.state.selectedFilter === filter.optionname
                            ? ' filtering-block_button--selected'
                            : '')
                    }
                    onClick={() => {
                        this.selectFilter(filter.optionname);
                    }}
                >
                    {filter.optionname}
                    <span>
                        ({selectedOptions}/{optionsNumber})
                    </span>
                </button>
                {
                    <div
                        className={
                            this.state.selectedFilter === filter.optionname
                                ? 'filtering-block__options-wrapper filtering-block__options-wrapper--open'
                                : 'filtering-block__options-wrapper'
                        }
                    >
                        <div className="filtering-block__options">
                            {this.getSelectedFilterOptions(
                                filter.optionname
                            ).map((options) => {
                                return this.renderOptions(
                                    options,
                                    filter.optionname
                                );
                            })}
                        </div>
                        <div className="filtering-block__options-buttons">
                            <button type="submit" className="button">
                                Bruk filter
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    this.resetOptions(filter.optionname);
                                }}
                                className="link-button"
                            >
                                Nullstill
                            </button>
                        </div>
                    </div>
                }
            </li>
        );
    }

    resetOptions(filter) {
        let updatedOptions = this.getSelectedFilterOptions(filter).map(
            (option) => {
                option.selected = false;
                return option;
            }
        );

        this.setState({
            options: this.updateOptionsForFilter(
                this.state.selectedFilter,
                updatedOptions
            ),
        });
    }

    resetFilters() {
        let options = this.state.options.slice();
        options.forEach((filter) => {
            filter.optionitems = filter.optionitems.map((option) => {
                option.selected = false;
                return option;
            });
        });
        this.setState({
            options: options,
        });
    }

    showFilters() {
        if (this.state.isFilteringVisible) {
            this.setState({ isFilteringVisible: false });
        } else {
            this.setState({ isFilteringVisible: true });
        }
    }

    render() {
        return (
            <div>
                <button
                    type="button"
                    className={
                        this.state.isFilteringVisible
                            ? 'filtering-block__open-button visible'
                            : 'filtering-block__open-button'
                    }
                    onClick={this.showFilters}
                >
                    {!this.state.isFilteringVisible && <span>Velg filter</span>}
                    {this.state.isFilteringVisible && <span>Skjul filter</span>}
                </button>
                <fieldset
                    className={
                        this.state.isFilteringVisible
                            ? 'visible filtering-block--' + this.props.layout
                            : 'filtering-block--' + this.props.layout
                    }
                >
                    <div className="filtering-block__buttons clearfix">
                        <div className="filtering-block__buttons-wrapper clearfix">
                            <legend>Velg filter</legend>
                            <button
                                type="submit"
                                className="link-button filtering-block__reset"
                                onClick={this.resetFilters}
                            >
                                Nullstill alle filtere
                            </button>
                        </div>
                        <ul>
                            {this.state.options.map(this.renderfilterButtons)}
                        </ul>
                        <div className="filtering-block__buttons-wrapper clearfix">
                            <button
                                type="submit"
                                className="link-button filtering-block__reset"
                                onClick={this.resetFilters}
                            >
                                Nullstill alle filtere
                            </button>
                        </div>
                    </div>
                </fieldset>
            </div>
        );
    }
}
