import { useLayoutEffect } from 'react';

/**
 * When using "UseLayoutEffect" React gives a warning saying
 *
 * "Warning: useLayoutEffect does nothing on the server,
 * because its effect cannot be encoded into the server renderer's output format.
 * This will lead to a mismatch between the initial, non-hydrated UI and the intended UI.
 * To avoid this, useLayoutEffect should only be used in components that render exclusively on the client.
 * See https://fb.me/react-uselayouteffect-ssr for common fixes.%s"
 *
 * The solution we're going for here is to simply not run useLayoutEffect when "window" is undefined
 * https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
 */

export const useBrowserLayoutEffect =
    typeof window !== 'undefined' ? useLayoutEffect : () => {};

export default useBrowserLayoutEffect;
