import React from 'react';
import PropTypes from 'prop-types';
import { sendAnalytics } from './registrationFormHelpers';

function SummaryEditButton({ buttonText, children, onClick, analyticsEvent }) {
  return (
    <button
      className="button"
      type="button"
      onClick={() => {
        onClick();
        sendAnalytics('Button click', analyticsEvent);
      }}
    >
      {children || buttonText}
    </button>
  );
}

SummaryEditButton.propTypes = {
  buttonText: PropTypes.string,
};

SummaryEditButton.defaultProps = {
  buttonText: 'Endre opplysninger',
};

export default SummaryEditButton;
