import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const InputLabelText = ({
    children,
    className,
    screenReaderOnly,
    required,
}) => (
    <span
        className={classNames(
            'input__label',
            { 'sr-only': screenReaderOnly },
            className
        )}
    >
        {children}
        {required && ' *'}
    </span>
);

InputLabelText.propTypes = {
    required: PropTypes.bool,
    screenReaderOnly: PropTypes.bool,
};

InputLabelText.defaultProps = {
    required: null,
    screenReaderOnly: null,
};

export default InputLabelText;
