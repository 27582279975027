import React from 'react';
import PropTypes from 'prop-types';
import { addQuery, defaultQueryKeys } from '../../Utils/urlUtils';

function getPaginationSettings(currentPage, pages) {
    const paginationSettings = {
        paginationStart: 0,
        paginationEnd: 0,
        paginationShowFirst: false,
        paginationShowStartEllipsis: false,
        paginationShowEndEllipsis: false,
        paginationShowLast: false,
    };
    if (currentPage <= 5) {
        paginationSettings.paginationStart = 1;
        paginationSettings.paginationShowFirst = false;
        paginationSettings.paginationShowStartEllipsis = false;
    } else {
        paginationSettings.paginationShowFirst = true;
        paginationSettings.paginationShowStartEllipsis = true;
        paginationSettings.paginationStart = currentPage - 2;
    }
    if (currentPage > pages - 5) {
        paginationSettings.paginationEnd = pages;
        paginationSettings.paginationShowEndEllipsis = false;
        paginationSettings.paginationShowLast = false;
    } else {
        paginationSettings.paginationEnd = currentPage + 2;
        paginationSettings.paginationShowEndEllipsis = true;
        paginationSettings.paginationShowLast = true;
    }
    return paginationSettings;
}

const pageLabelString = 'Gå til side';
const currentPageLabelString = `Side`;

function renderPages(currentPage, paginationSettings, pageChangeFunction) {
    const { paginationStart, paginationEnd } = paginationSettings;
    const pageButtons = [];
    for (let i = paginationStart; i <= paginationEnd; i += 1) {
        const isCurrentPage = i === currentPage;
        pageButtons.push(
            <button
                type="button"
                key={i}
                className={isCurrentPage ? 'active' : ''}
                aria-disabled={isCurrentPage}
                aria-current={isCurrentPage}
                onClick={() => pageChangeFunction(i)}
                aria-label={
                    isCurrentPage
                        ? `${currentPageLabelString} ${i}`
                        : `${pageLabelString} ${i}`
                }
            >
                {i}
            </button>
        );
    }
    return pageButtons;
}

function Pagination({ currentPage, onPageChange, pages, className, urlKey }) {
    const paginationSettings = getPaginationSettings(currentPage, pages);
    const {
        paginationShowFirst,
        paginationShowStartEllipsis,
        paginationShowLast,
        paginationShowEndEllipsis,
    } = paginationSettings;

    return (
        <nav
            className={`pagination ${className || ''}`}
            aria-label="Paginering"
        >
            {currentPage > 1 && (
                <button
                    type="button"
                    className="prev"
                    onClick={() => {
                        onPageChange(currentPage - 1);
                    }}
                >
                    <span className="sr-only">Forrige</span>
                </button>
            )}

            {paginationShowFirst && (
                <button type="button" onClick={() => onPageChange(1)}>
                    {' '}
                    1
                </button>
            )}
            {paginationShowStartEllipsis && <span>&hellip;</span>}

            {renderPages(currentPage, paginationSettings, onPageChange)}
            {paginationShowEndEllipsis && <span>&hellip;</span>}
            {paginationShowLast && (
                <button type="button" onClick={() => onPageChange(pages)}>
                    {pages}
                </button>
            )}

            {currentPage < pages && (
                <button
                    type="button"
                    className="next"
                    onClick={() => {
                        onPageChange(currentPage + 1);
                    }}
                >
                    <span className="sr-only">Neste</span>
                </button>
            )}
        </nav>
    );
}

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    urlKey: PropTypes.string,
    className: PropTypes.string,
};

Pagination.defaultProps = {
    urlKey: defaultQueryKeys.page,
    className: null,
};

export default Pagination;
