import React, { useState } from 'react';
import axios from 'axios';
import { Field, useFormikContext } from 'formik';
import { sendAnalytics } from './registrationFormHelpers';
import AutosuggestSelect from '../autosuggestSelect';
import ValidationErrorFormik from '../../UI/forms/formik/ValidationErrorFormik';
import DefaultButton from '../../UI/buttons/DefaultButton';
import InputWrapper from './inputWrapper';

const urlWorkPlace = '/api/arbeid/arbeidssted/?workplace='; // Work place lookup
const urlWorkPosition = '/api/arbeid/stilling/?position='; // Work Position lookup
const urlWorkType = '/api/arbeid/arbeidsplasstype/'; // Work type lookup
function WorkplaceFields({
  formKey,
  workplaceIndex,
  preventSubmitOnEnter,
  addWorkplace,
  removeWorkplace,
}) {
  const { setFieldValue, values } = useFormikContext();
  const [workplaceTypeError, setWorkplaceTypeError] = useState(false);
  const { workplaces: availableWorkplaces } = values;
  const workplaceData = availableWorkplaces[workplaceIndex];
  const workplaceKey = `${formKey}[${workplaceIndex}]`;

  const getWorkplaceTypes = (id) => {
    axios
      .get(urlWorkType + id)
      .then((response) => {
        const newWorkplaceTypes = response.data.map((workplace) => ({
          value: workplace.arbPlassKodeNavn,
          name: workplace.arbPlassKodeNavn,
          kode: workplace.arbPlassKode,
          key: workplace.tariffKode,
        }));
        setFieldValue(`${workplaceKey}[workplaceTypes]`, newWorkplaceTypes);
        setFieldValue(
          `${workplaceKey}[workplaceTypeName]`,
          newWorkplaceTypes[0].name
        );
        setFieldValue(
          `${workplaceKey}[workplaceType]`,
          newWorkplaceTypes[0].kode
        );
      })
      .catch(() => {
        setWorkplaceTypeError(true);
      });
  };

  const setWorkplaceData = (value, i) => {
    setFieldValue(`${workplaceKey}[workplaceName]`, value.data.name);
    setFieldValue(`${workplaceKey}[unionChapter]`, value.data.foreningsNavn);
    setFieldValue(`${workplaceKey}[unionChapterId]`, value.data.foreningsId);
    setFieldValue(`${workplaceKey}[workplaceId]`, value.data.id);
    setFieldValue(`${workplaceKey}[address]`, value.data.address);
    setFieldValue(`${workplaceKey}[zipCode]`, value.data.zipCode);
    setFieldValue(`${workplaceKey}[city]`, value.data.city);
    getWorkplaceTypes(value.data.id, i);
  };

  const workplaceNumber = workplaceIndex + 1;
  const { workplaceTypes } = workplaceData || {};
  return (
    <div className="workplace">
      <h3>Arbeidsplass {workplaceNumber}</h3>
      <InputWrapper
        label="Arbeidsplass"
        inputId={`${workplaceKey}[workplaceName]`}
        isRequired
      >
        <Field
          component={AutosuggestSelect}
          value={workplaceData.workplaceName}
          onChange={(val) => {
            setWorkplaceData(val, workplaceIndex);
          }}
          onFocus={() => {
            sendAnalytics('Field focus', `Arbeidsplass ${workplaceNumber}`);
          }}
          name={`${workplaceKey}[workplaceName]`}
          url={urlWorkPlace}
          dataValue="name"
          dataName="name"
          placeholder="Søk etter arbeidsplass"
        />
        <ValidationErrorFormik fieldName={`${workplaceKey}[workplaceName]`} />
        <input
          type="hidden"
          name={`${workplaceKey}[workplaceId]`}
          id="work_0_workplaceId"
          value=""
        />
        <input
          type="hidden"
          id="work_1_unionChapterId"
          name={`${workplaceKey}[unionChapterId]`}
          value=""
          data-valid="true"
        />
      </InputWrapper>
      <InputWrapper
        label="Arbeidsplasstype"
        inputId={`${workplaceKey}[workplaceType]`}
        isRequired
      >
        <Field
          component="select"
          name={`${workplaceKey}[workplaceType]`}
          onChange={(value) => {
            setFieldValue(
              `${workplaceKey}[workplaceTypeName]`,
              value.target.options[value.target.selectedIndex].text
            );
            setFieldValue(`${workplaceKey}[workplaceType]`, value.target.value);
          }}
          onFocus={() => {
            sendAnalytics(
              'Field focus',
              `Arbeidsplasstype ${workplaceIndex + 1}`
            );
          }}
        >
          {workplaceTypes &&
            workplaceTypes.map((option) => (
              <option key={option.key + option.kode} value={option.kode}>
                {option.name}
              </option>
            ))}
        </Field>
      </InputWrapper>
      {workplaceTypeError && (
        <div className="validation-msg">
          Beklager det har oppstått en teknisk feil. Prøv igjen senere.
        </div>
      )}
      {workplaceData.workplaceId && (
        <div>
          <div className="mt-4">
            <p>
              <b className="db">Adresse:</b>
              <br />
              {workplaceData.address && workplaceData.address}
              {!workplaceData.address && workplaceData.workplaceName}
              <br />
              {workplaceData.zipCode}, {workplaceData.city}
            </p>
            <p>
              <b className="db">Forening eller lokallag:</b>
              <br />

              {workplaceData.unionChapter}
            </p>
            <br />
          </div>
        </div>
      )}
      <InputWrapper
        label="Stilling"
        inputId={`${workplaceKey}[position]`}
        isRequired
      >
        <Field
          component={AutosuggestSelect}
          onKeyPress={preventSubmitOnEnter}
          value={workplaceData.position}
          onChange={(value) => {
            setFieldValue(`${workplaceKey}[positionId]`, value.data.id);
          }}
          onFocus={() => {
            sendAnalytics(
              'Field focus',
              `Arbeidsplass Stilling ${workplaceNumber}`
            );
          }}
          name={`${workplaceKey}[position]`}
          url={urlWorkPosition}
          dataValue="name"
          dataName="name"
          placeholder="Søk etter arbeidsplass"
        />
        <ValidationErrorFormik fieldName={`${workplaceKey}[position]`} />

        <input
          type="hidden"
          id="work_0_positionId"
          name={`${workplaceKey}[positionId]`}
          value=""
          onKeyPress={preventSubmitOnEnter}
        />
      </InputWrapper>
      <InputWrapper
        label="Stillingsprosent"
        inputId={`${workplaceKey}[positionPercent]`}
        isRequired
      >
        <Field
          type="text"
          component="input"
          name={`${workplaceKey}[positionPercent]`}
          onKeyPress={preventSubmitOnEnter}
          onFocus={() => {
            sendAnalytics(
              'Field focus',
              `Arbeidsplass Stillingsprosent ${workplaceNumber}`
            );
          }}
        />
        <ValidationErrorFormik fieldName={`${workplaceKey}[positionPercent]`} />
      </InputWrapper>
      <div className="workplace-actions">
        {addWorkplace && (
          <DefaultButton onClick={addWorkplace}>
            Legg inn et arbeidsforhold til
          </DefaultButton>
        )}
        {workplaceIndex > 0 && (
          <DefaultButton
            className="remove-btn"
            onClick={() => removeWorkplace(workplaceData.id)}
          >
            Fjern arbeidsforhold
          </DefaultButton>
        )}
      </div>
    </div>
  );
}

WorkplaceFields.propTypes = {};

export default WorkplaceFields;
