import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useEscapeKey } from 'SharedFrontend/src/Hooks';
import ToggleContainer from 'SharedFrontend/src/Components/ToggleContainer/ToggleContainer';
import EpiLink from 'UI/Links/EpiLink';
import Bleed from 'UI/Elements/Bleed';
import BurgerButton from 'UI/buttons/BurgerButton';
import MenuItemList from 'UI/Elements/MenuItemList';

const MegaMenu = ({
    menuItems,
    loginLink,
    logoutLink,
    membershipLink,
    profileLink,
    personalMenuItems,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    useEscapeKey(() => setIsOpen(false), isOpen);

    const megaMenuId = 'mega-menu';

    return (
        <>
            <BurgerButton
                id="mega-menu-button"
                menuOpen={isOpen}
                onClick={setIsOpen}
                menuID={megaMenuId}
                menuLabel="Meny"
            />
            <ToggleContainer
                shouldHide={!isOpen}
                id={megaMenuId}
                aria-expanded={isOpen}
                wrapperElement="nav"
            >
                <div className="menu-content">
                    <div className="main-menu">
                        <MenuItemList menuItems={menuItems} />
                    </div>
                    <div className="personal-menu">
                        <Bleed />
                        <div>
                            {membershipLink && (
                                <EpiLink
                                    className="membership-link"
                                    linkModel={membershipLink}
                                />
                            )}
                        </div>
                        <div>
                            <div className="user-name-wrapper">
                                {profileLink && (
                                    <span className="user-name">
                                        {profileLink.linkText}
                                    </span>
                                )}
                                {logoutLink && (
                                    <EpiLink
                                        className="logout-link"
                                        linkModel={logoutLink}
                                    />
                                )}
                            </div>
                            <MenuItemList menuItems={personalMenuItems} />
                        </div>
                        <div>
                            {loginLink && (
                                <EpiLink
                                    className="login-link"
                                    linkModel={loginLink}
                                />
                            )}
                            {logoutLink && (
                                <EpiLink
                                    className="logout-link"
                                    linkModel={logoutLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </ToggleContainer>
        </>
    );
};

MegaMenu.propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    loginLink: PropTypes.string.isRequired,
    logoutLink: PropTypes.string.isRequired,
    membershipLink: PropTypes.string.isRequired,
    profileLink: PropTypes.string.isRequired,
    personalMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MegaMenu;
