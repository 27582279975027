import React, { useEffect } from 'react';

export default function useEscapeKey(onKeyFunction, active = true) {
    useEffect(() => {
        function handlekeydownEvent(event) {
            const { key, keyCode } = event;
            if (keyCode === 27) {
                onKeyFunction();
            }
        }
        if (active) {
            document.addEventListener('keyup', handlekeydownEvent);
        }
        return () => {
            document.removeEventListener('keyup', handlekeydownEvent);
        };
    }, [active]);
}
