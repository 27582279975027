import React from 'react';
import PropTypes from 'prop-types';

const BaseLink = ({ linkModel, className, children }) => {
    const { linkUrl, linkText, target, group } = linkModel;

    return (
        <a
            href={linkUrl}
            target={target}
            data-group={group}
            className={className}
        >
            {children}
            {/* Pedstud wanted the option to use &shy; in their menu items */}
            <span dangerouslySetInnerHTML={{ __html: linkText }} />
        </a>
    );
};

BaseLink.propTypes = {
    target: PropTypes.string,
    className: PropTypes.string,
    linkModel: PropTypes.shape({
        linkUrl: PropTypes.string,
        linkText: PropTypes.string,
        target: PropTypes.string,
        group: PropTypes.string,
    }).isRequired,
    children: PropTypes.node,
};

BaseLink.defaultProps = {
    target: null,
    className: '',
};

export default BaseLink;
