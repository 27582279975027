import React from 'react';
import PropTypes from 'prop-types';

const InformationLink = ({ link, title }) => {
  return (
    <a href={link} className="read-more">
      {title}
      <i>
        <svg
          width="19px"
          height="13px"
          viewBox="0 0 19 13"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            fillRule="nonzero"
            points="12.5838414 0 11.8306912 0.76302354 16.960795 5.96037939 0 5.96037939 0 7.0396206 16.960795 7.0396206 11.8306912 12.2372463 12.5838414 13 19 6.5"
          />
        </svg>
      </i>
    </a>
  );
};

InformationLink.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default InformationLink;
