import PubSub from 'pubsub-js';

/**
 * Since we don't have a common root component while using React.Net, we use
 * a pub/sub system instead that's handled outside of React, most of the time
 * it should be good enough.
 * (Note: It's possible to get redux to work, but it's too much hassle for what we
 * need right now https://github.com/reactjs/React.NET/issues/771)
 * Another option could be to use https://github.com/pmndrs/zustand#using-zustand-without-react?
 */

export const eventTypes = {
    ACCORDION_TOGGLE: 'accordionToggle',
};

/** Generic subscribe function that can be reused */
const subscribe = (topic, subscribingFunction) => {
    if (typeof subscribingFunction === 'function') {
        const token = PubSub.subscribe(topic, subscribingFunction);
        return token;
    }
    return null;
};

export const unSubScribe = token => {
    PubSub.unsubscribe(token);
};

export const accordionToggle = {
    subscribe: subscribingFunction =>
        subscribe(eventTypes.ACCORDION_TOGGLE, subscribingFunction),
    publish: open => PubSub.publish(eventTypes.ACCORDION_TOGGLE, open),
};

export default { accordionToggle, eventTypes };
