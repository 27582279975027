import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BaseButton from './BaseButton';

const DefaultButton = ({ className, ...props }) => (
    <BaseButton
        {...props}
        className={classNames('button vspace--medium', className)}
    />
);

DefaultButton.propTypes = {
    className: PropTypes.string,
};

DefaultButton.defaultProps = {
    className: '',
};

export default DefaultButton;
